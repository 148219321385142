import React from 'react';
import { URL } from '../variables';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import './alert.css';
import moment from 'moment';

class RecoveryPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            faculdade: null,
            loading: false,
            loading_send: false,
            msgErro: '',
            credencial: '',
            password: '',
            redirect: false,
            path: '',
            msgSuccess:'',
            send:false
        }

    }

    login(event) {
        event.preventDefault();
        this.setState({ loading_send: true, msgErro: '' });
        let faculdade_id = null;
        if (this.state.faculdade != null) {
            faculdade_id = this.state.faculdade.id;
        }
        fetch(`${URL}api/auth/send_reset_link`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpf: this.state.credencial,
                faculdade_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, loading_send: false, msgErro: erro, refresh: false, loadingMore: false });
                }
                else {
                    this.setState({ loading_send: false,msgSuccess:'Um link de recuperação foi enviado para o seu e-mail',send:true,credencial:'' });
                    // localStorage.setItem('token', resp.access_token);
                    // this.props.login({ token: resp.access_token, user: {} });
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, loading_send: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, loading_send: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }

    get_faculdade(slug) {
        fetch(`${URL}api/get_faculdade/${slug}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro, });
                }
                else {
                    if (resp.faculdade != null) {
                        this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    }
                    else {
                        this.setState({ redirect: true, path: '/', loading: false });
                    }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', });
            });
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({ loading: true, msgErro: '' });
        this.get_faculdade(params.slug);
    }


    changeCPF(event) {
        let text = event.target.value
        let final_num = '';

        if (text.length > 0) {
            text = text.replace('.', '').replace('-', '').replace('.', '');
            if (text.length <= 11) {


                for (let i = 0; i < text.length; i++) {
                    if (!isNaN(text[i])) {
                        if (i == 3) {
                            final_num += '.' + text[i];
                        }
                        else if (i == 6) {
                            final_num += '.' + text[i];
                        }
                        else if (i == 9) {
                            final_num += '-' + text[i];
                        }
                        else {
                            final_num += text[i] + '';
                        }
                    }
                }
                // this.setState({ cpf: final_num });
                this.setState({ credencial: final_num })
            }

        }
        else {
            this.setState({ credencial: final_num })
        }


    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        final_num = text;
        this.setState({ credencial: final_num })
    }

    changeCPFEmail(event) {
        let text = event.target.value
        let final_num = '';
        if (text.length > 0 && isNaN(text[0])) {
            final_num = text;
            this.setState({ credencial: final_num })
        }
        else if (text.length > 0) {
            text = text.replace('.', '').replace('-', '').replace('.', '');
            if (text.length <= 11) {


                for (let i = 0; i < text.length; i++) {
                    // if (Number.isInteger(text[i])) {
                    if (i == 3) {
                        final_num += '.' + text[i];
                    }
                    else if (i == 6) {
                        final_num += '.' + text[i];
                    }
                    else if (i == 9) {
                        final_num += '-' + text[i];
                    }
                    else {
                        final_num += text[i] + '';
                    }
                    // }
                }
                // this.setState({ cpf: final_num });
                this.setState({ credencial: final_num })
            }

        }
        else {
            this.setState({ credencial: final_num })
        }


    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    render() {
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                    <span className="sr-only">Loading...</span>
                </div></div>}
                {!this.state.loading && this.state.faculdade != null && <div className="row" style={{ backgroundColor: 'white' }}>
                    <div className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4" style={{ margin: 0, padding: 0 }}>
                        <div className="card" style={{ height: '100vh', margin: 0 }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                {this.state.faculdade != null && <div className="text-center">
                                    <a className="logo"><img src={this.state.faculdade.logo}
                                        height="80" alt="logo" /></a>
                                </div>}

                                {this.state.faculdade == null && <div className="text-center">
                                    <br/>
                                    <a className="logo"><img src={require('../assets/images/logo-ontest.png')}
                                        height="60" alt="logo" /></a>
                                    <br/>
                                </div>}

                                <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                    <h4 className="font-18 m-b-5 text-center"  style={{fontWeight:600}}>Bem vindo !</h4>
                                    <p className="text-muted text-center">Escreva seu CPF para poder recuperar sua senha.</p>

                                    <form className="form-horizontal m-t-30" onSubmit={this.login.bind(this)}>

                                        <div className="form-group row">


                                            {this.state.msgSuccess!='' && <div className="col-12"><div className="alert alert-success2  alert-dismissible fade show" role="alert">
                                                <button onClick={() => this.setState({ msgSuccess: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <p style={{ margin: 0 }}>{this.state.msgSuccess}</p>
                                            </div></div>}
                                            {<div className="col-12">
                                                <label style={{color:'black',marginBottom:'0.5rem',fontSize:14}}>CPF <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                                                <input id="credencial" type="username"
                                                    className="form-control  " style={{marginBottom:'1rem',fontSize:14}} name="credencial"
                                                    value={this.state.credencial} onChange={this.changeCPF.bind(this)} required autoFocus />

                                            </div>}


                                        </div>




                                        <div className="form-group row m-t-20">
                                            <div className="col-sm-12">
                                                <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                    <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                            {this.state.loading_send == false && <div className="col-sm-12 text-right">
                                                <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%',fontSize:14, marginTop: '15px', height: '3rem', backgroundColor: this.state.faculdade != null ? this.state.faculdade.primaria : '' }} type="submit">Recuperar</button>

                                            </div>}
                                            {this.state.loading_send == true && < div className="col-sm-12 d-flex justify-content-center align-items-center">
                                                <div className="spinner-border text-primary" role="status"  >
                                                    <span className="sr-only">Loading...</span>
                                                </div></div>}
                                        </div>

                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>

                                                {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                                                {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-center">
                                    <p style={{fontSize:14,marginTop:'1rem'}}>© {moment().format('YYYY')} OnTest</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block" style={{ overflow: 'hidden', backgroundImage: `url(${require("../assets/images/bg_vestibular.jpeg")})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(RecoveryPassword);
