import React from 'react';

import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';

import { Modal, Button } from 'react-bootstrap'
import { URL } from '../variables';
import { ArrowRight } from '@phosphor-icons/react';

import ExemploImagem from '../assets/images/exemplo.gif'

class RequestAccepted extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            loading: false,
            loading_content: false,
            acao: '',
            msgErro: '',
            message: '',
            provas: []
        }
    }



    check_eligibility_to_pause() {
        this.setState({ loading_content: true });
        fetch(`${URL}api/check_eligibility_to_pause`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                processo_id: this.props.processo.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else if (resp.message == 'Server Error' || resp.status == false) {
                    if (this.unmount == false) {
                        this.timer_check = setTimeout(() => {
                            this.check_eligibility_to_pause()
                        }, 5000);
                    }
                }
                else {
                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    // let provas = [...this.props.provas];
                    // provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
                    // this.props.mudarProvas(provas);
                    // this.setState({ loading_resposta, show_success: true, msg_success: 'Questão ' + indice + ') respondida com sucesso' });
                    // setTimeout(()=>{
                    this.setState({ provas: resp.provas, loading_content: false })
                    // },30000);



                }




            } catch (err) {
                console.log(err);
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                if (this.unmount == false) {
                    this.timer_check = setTimeout(() => {
                        this.check_eligibility_to_pause()
                    }, 5000);
                }

            }

        })
            .catch((err) => {
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                // console.log(err);
                if (this.unmount == false) {
                    this.timer_check = setTimeout(() => {
                        this.check_eligibility_to_pause()
                    }, 5000);
                }
                // this.props.mudarLoadingHome(false);
            });
    }

    componentDidMount() {
        this.unmount = false;
        this.check_eligibility_to_pause();

    }

    componentWillUnmount() {
        clearTimeout(this.timer_check);
        this.unmount = true;
    }

    componentDidUpdate(props) {
        if (props.show_pause_modal_accepted != this.props.show_pause_modal_accepted && this.props.show_pause_modal_accepted == true &&
            this.state.loading_content == false
        ) {
            this.check_eligibility_to_pause();
        }
    }





    render() {
        
        return (
            <>



                <Modal
                    show={this.props.show_pause_modal_accepted}
                    onHide={() => this.props.setModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{ color: 'black' }}>Sua Solicitação de Pausa Foi Aceita</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.loading_content == false ? <>
                            {this.state.msgErro != '' && <div>
                                <div className="alert alert-danger alert-dismissible fade show mb-0 d-flex" role="alert">


                                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                                    {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                </button> */}
                                </div>
                                <br />

                            </div>}
                            {this.props.user.id != undefined && <div className="form-group row mb-0">
                                <div className="col-sm-12">
                                    <p style={{ color: 'black', marginBottom: 5, fontSize: 16 }}><b style={{ marginRight: "2px" }}>Nome: </b>{this.props.user.name}</p>
                                </div>
                            </div>}
                            {this.state.provas.length > 0 && <>
                                {<p style={{ color: 'black', marginBottom: 5, fontSize: 16 }}>Ao iniciar a pausa, <b>você não poderá mais alterar as respostas das questões que você já visualizou.</b></p>}
                                {<p style={{ color: 'black', marginBottom: 5, fontSize: 16 }}>Para acessar essas questões, navegue clicando na questão pelo controle de resposta. Exemplo:</p>}
                                <div style={{display:"flex",justifyContent:'center',marginTop:10,marginBottom:10}}>
                                    <img src={ExemploImagem} style={{width:'100%',maxWidth:600, objectFit:'contain'}} />
                                </div>

                                {this.state.provas.map((item, id) => (
                                    <div key={id}>
                                        <p style={{ color: 'black', marginBottom: 5, fontSize: 16 }}><b>Prova:</b> {item.prova.name} </p>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Questão</th>
                                                            <th>Foi respondida?</th>
                                                            <th data-priority="3">Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.questions_accessed.map((item2, id2) => (

                                                            <tr key={id2}>
                                                                <td >{item2.select_question})</td>
                                                                <td >{item.respostas[item2.questao_id]!=undefined ? <span style={{ color: 'green' }}>Respondida</span> : <span style={{ color: 'red' }}>Não respondida</span>}</td>
                                                                <td>
                                                                    {<span
                                                                        onClick={(e) => {
                                                                            this.props.goToQuestion(item.prova.id,item2.questao_id,item2.select_question);
                                                                        }}
                                                                        className="btn btn-info"
                                                                        style={{  borderRadius: "7px" }}
                                                                        title={"Acessar questão " + item2.select_question}
                                                                    >
                                                                        Acessar Questão <ArrowRight size={17} color="white" />
                                                                    </span>}
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </>}

                        </> : <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div></div>}


                    </Modal.Body>
                    {this.state.loading == false && this.state.loading_content == false && <Modal.Footer>
                        <Button variant="secondary" className="btn-lg" onClick={() => this.props.setModal(false)}>Fechar</Button>
                        <Button className="btn-lg" onClick={() => {

                            this.props.sendRequest(this.state.message)
                            this.setState({ message: '', msgErro: '' });
                            this.props.setModal(false);
                        }}>Iniciar</Button>

                    </Modal.Footer>}
                    {this.state.loading == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                </Modal>

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(RequestAccepted);
