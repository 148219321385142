import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';


import './assets/css/metismenu.min.css';
import './assets/css/icons.css';
import './assets/css/style.css';
import './assets/css/bootstrap.min2.css';
import './Home/text.css';
import { DEBUG } from './variables';

if(!DEBUG){
  console.log = () => {};
}
// display: block;
//     margin-block-start: 1em;
//     margin-block-end: 1em;
//     margin-inline-start: 0px;
//     margin-inline-end: 0px;
document.oncontextmenu = function (e) {
      e.preventDefault();

}

ReactDOM.render(
  <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
