import React, {  Component } from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import { mudarStream, mudarDevices, mudarCamModal } from '../actions/AppActions';
import { connect } from 'react-redux';
import moment from 'moment';

class SelectProccess extends Component {


   
    closeModal() {
        this.props.setShowProccess(false);
    }

    

    render() {

        return (
            <div style={{color:'black'}}>
                {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
                <Modal show={this.props.show_proccess} onHide={()=>this.closeModal()}  >
                    <Modal.Header closeButton={false}><Modal.Title>Escolha um dos cargos para fazer a prova</Modal.Title></Modal.Header>
                    <Modal.Body>
                        {this.props.processos.map((item,id)=>{
                            return(
                                <div key={item.id} className='box_seletive'
                                onClick={()=>{
                                    this.props.selectProccess(item.id);
                                }}
                                >
                                    <p style={{fontSize:20,textAlign:'center'}}><b>{item.name}</b></p>
                                    <p style={{margin:0}}><b>Início:</b> {moment(item.inicio).format('DD/MM/YYYY HH:mm')}</p>
                                    <p style={{margin:0}}><b>Encerramento:</b> {moment(item.fim).format('DD/MM/YYYY HH:mm')}</p>
                                </div>
                            );
                        })}

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button color="primary" onClick={this.change_device.bind(this)}>Alterar</Button>{' '} */}
                        <Button color="secondary" onClick={this.closeModal.bind(this)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

const mapsStateToProps = (state) => (
    {
        
    }
);

export default connect(mapsStateToProps, { mudarCamModal, mudarDevices, mudarStream })(SelectProccess);