import { Tooltip } from "@mui/material";
import { ArrowLineRight } from "@phosphor-icons/react";
import style from "./style.module.css";
import { useEffect, useRef } from "react";
import { Microphone, MicrophoneSlash } from "@phosphor-icons/react/dist/ssr";
import { Autorenew } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";

export default function Info({
  show_info,
  setShowInfo,
  getAdminId,
}) {
  console.log('show_info',show_info)

  const entrevistas = useSelector(store=>store.AppReducer.entrevistas);
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        maxWidth:'50vw',
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem",
        padding: "1rem",
        position: "absolute",
        top: "0",
        right: show_info ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Informações da avaliação
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder informações
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowInfo(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* participants container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {entrevistas.map((item,id)=>(

        <div key={id} style={{ marginTop: '1rem' }}>
          <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
            {item.entrevista.name}</h5>
          <p style={{ whiteSpace: 'pre-line' }}><b style={{ color: 'black', fontSize: 18 }}>Descrição:</b> <div dangerouslySetInnerHTML={{ __html: item.entrevista.descricao }}></div></p>
          <div style={{ marginTop: 10 }}>
            {item.entrevista.topicos.map((item2, id2) => (
              <div key={id2} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}><div style={{ background: 'black', borderRadius: 100, minWidth: 6, minHeight: 6, marginRight: 5 }}></div> <span>{item2.text}</span></div>
            ))}
          </div>
          <p style={{ color: 'black', textAlign: 'center', marginBottom: 10 }}><b >Início:</b> {item.exam_oral?.datetime_initial ? moment(item.exam_oral?.datetime_initial).format('DD/MM/YYYY HH:mm') : '-'}</p>
          <p style={{ color: 'black', textAlign: 'center', marginBottom: 10 }}><b >Fim:</b> {item.exam_oral?.datetime_end ? moment(item.exam_oral?.datetime_end).format('DD/MM/YYYY HH:mm') : '-'}</p>

          
          <hr />
        </div>
        ))}

      </div>
    </div>
  );
}

