import React from 'react';
import ProfileMenu from './ProfileMenu';
import { URL } from '../variables';
import { connect } from 'react-redux';


class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="topbar">

                <div className="topbar-left" style={{ background: '#ececf1' }}>
                    {this.props.faculdade.logo != null && <a className="logo">
                        <span>
                            <img src={this.props.faculdade.logo} alt="" height='60' />
                        </span>
                        <i>
                            <img className="img-fluid" src={this.props.faculdade.logo} alt="" height="auto" style={{maxHeight:60}} />
                        </i>
                    </a>}
                </div>
                <nav className="navbar-custom" style={{display:'flex',justifyContent:'flex-end'}}>
                    <ul className="navbar-right d-flex list-inline float-right mb-0">
                        <ProfileMenu />


                    </ul>



                </nav>
            </div>

        );
    }


}

const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade
    }
);

export default connect(mapsStateToProps, {})(Header);