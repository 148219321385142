import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import ChangeCamModal from './changeCamModal';
import { logout } from '../actions/AppActions';

class NotProcesso extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }

        // this.videoRef=null;
    }

    componentDidMount(){
        this.logs_timeout=setTimeout(()=>{
            this.salvar_log();
        },30000);
    }

    componentWillUnmount(){
        clearTimeout(this.logs_timeout);
    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=not_processo`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
    
            'Authorization': `Bearer ${this.props.token}`,
            // 'Content-Type': 'application/json',
          }
        }).then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.message == "Unauthenticated.") {
              localStorage.removeItem('token');
              // this.setState({redirect:true,path:'/'});
              this.props.logout();
            }
            else {
                this.logs_timeout=setTimeout(()=>{
                    this.salvar_log();
                },30000);
            }
          } catch (err) {
            console.log(err);
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, 30000);
          }
    
        })
          .catch((err) => {
            console.log(err);
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, 30000);
          });
      }


    render() {
        return (
            <div className="row">


                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>

                            <hr />
                            <div className="row">

                                <div className="col-12 ">
                                    <div className="form-group row">

                                        <div className="col-12 col-md-12" style={{ objectFit: 'contain' }}>


                                            <div style={{ height: '200px' }}>


                                                <div className="project-item" style={{ marginBottom: 0 }}>
                                                    <div className="overlay-container"
                                                        style={{ height: '200px', display: 'flex', justifyContent: 'center' }}>
                                                        {this.props.faculdade.logo != null && <img src={this.props.faculdade.logo} alt="img"
                                                            style={{ height: '200px', objectFit: 'contain', borderRadius: '10px' }} />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>
                            <hr />
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black',textAlign:'center' }}>Aviso</h4>
                            <div
                                style={{ color: 'red', padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>
                                Este usuário não está vinculado a nenhum processo seletivo
                      </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { logout })(NotProcesso);
