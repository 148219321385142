
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout,mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto:'',
            chat_open:false,
            first:true,
            loading_chat:true
        };
        this.messages_view=React.createRef();
    }



    componentDidMount() {
        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        clearTimeout(this.timeout_get_messages);
    }

   
    componentDidUpdate(props,state){
        if(this.state.chat_open==true && state.chat_open==false || props.mensagens.length!=this.props.mensagens.length){

            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        }
        
        if(this.state.first==true && (this.state.chat_open==true || this.props.show_call==true)){
            this.setState({first:false});
            this.get_messages();
        }

    }

    get_messages() {

        if (this.state.loading_chat == false) {
            this.setState({ loading_chat: true });
        }
        fetch(`${URL}api/get_messages/${this.props.processo.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                    this.timeout_get_messages = setTimeout(() => {
                        this.get_messages();
                    }, 5000);
                }
                else {
                    this.props.mudarMensagens(resp.mensagens);
                    this.props.socket.emit('msg_receive', {processo_id:this.props.processo.id, sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });

                    this.setState({loading_chat:false},()=>{
            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

                    });

                }
            } catch (err) {
                // this.setState({ redirect: true });
                console.log(err);
                this.timeout_get_messages = setTimeout(() => {
                    this.get_messages();
                }, 5000);
            }

        })
            .catch((err) => {
                // this.setState({ redirect: true });

                console.log(err);
                this.timeout_get_messages = setTimeout(() => {
                    this.get_messages();
                }, 5000);
            });
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    



    render() {

        return (
            <div
                hidden={this.props.show_call && (this.props.user.accessibility==false || this.props.user.type_supervisor=='none')}
                style={{
                    background: 'white', height: '440px', width: '300px',
                    position: 'fixed',
                    bottom: this.state.chat_open==false?'-400px':0,
                    right: '15px',
                    zIndex: 2000, wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">

                <a id="close_chat"
                    onClick={()=>{
                        this.setState({chat_open:!this.state.chat_open});
                    }}
                    style={{ borderTopLeftRadius:'5px',borderTopRightRadius:'5px', backgroundColor: '#222', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <p style={{ margin: 0, color: 'white', textAlign: 'center' }}>Fiscal</p>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '2rem',
                        background:'rgb(34, 34, 34)',
                        padding:0,
                        lineHeight:'initial'

                    }}>
                        <span aria-hidden="true"><i className="mdi mdi-open-in-new"></i></span>
                    </button>
                </a>
                {this.state.loading_chat==true && <div style={{position:'absolute',top:40,right:0,left:0,bottom:0}}>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%',background:'rgba(255,255,255,0.6)' }}>
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </div>
                    <h6 style={{ textAlign: 'center' }}>Carregando mensagens...</h6>
                </div>}

                <div ref={this.messages_view} style={{ height: '330px', padding: 0, margin: 0 }} className="overflow-auto" id="body_msg">
                    {this.props.mensagens.map((item, id) => {
                        if(item.type_message=='message'){
                            if (item.sender_id == this.props.user.id) {
                                return (
                                    <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '3rem', borderRadius: '5px', textAlign: 'justify',  alignItems: 'flex-end' }} >
                                            <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem' }}>{item.message}</p>
                                            <div style={{display: 'flex', justifyContent: 'flex-end',alignItems:'center'}}>
                                            
                                            <span style={{ fontSize: 10, marginRight: 2 }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>

                                            {item.visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                            
                                            {item.visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                            {item.visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                            </div>
                                       
                                        </div>
                                    </div>
                                )
                            }
                            else if (item.receive_id == this.props.user.id) {
                                return (
                                    <div key={id} style={{display:'flex',alignItems: 'flex-start',justifyContent:'flex-start',marginTop: '0.5rem',marginBottom:'0.5rem'}}>
                                        <div style={{padding:'5px',backgroundColor: 'azure',marginRight:'3rem',marginLeft:'0.5rem',borderRadius:'5px',textAlign:'justify'}}>
                                            <p style={{marginBottom: 0,color: 'blue',fontWeight: 'bold',fontSize: '0.9rem'}}>{item.sender_name}</p>
                                            <p style={{marginBottom: 0,color:'black'}}>{item.message}</p>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        else if(item.type_message=='comunicado'){
                            return (
                                <div key={id} style={{display:'flex',alignItems: 'flex-start',justifyContent:'flex-start',marginTop: '0.5rem',marginBottom:'0.5rem'}}>
                                    <div style={{padding:'5px',backgroundColor: 'white',marginRight:'3rem',marginLeft:'0.5rem',borderRadius:'5px',textAlign:'justify',border: '1px dashed gray'}}>
                                        <p style={{marginBottom: 0,color: 'black',fontWeight: 'bold',fontSize: '0.9rem'}}>{item.name_comunicado}</p>
                                        <p style={{marginBottom: 0,color:'black'}}>{item.message}</p>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                    </div>
                                </div>
                            );
                        }
                        
                    })}
                </div>
                
                {this.props.processo.chat_type =='all' && <div
                    style={{height:'70px',backgroundColor:'#f2f2f2',borderTop:'1px solid lightgrey',padding:'5px',display:'flex'}}>
                        <input autoComplete="off" onKeyPress={(e)=>{
                            let keyCode = e.keyCode || e.which;
                            if(keyCode==13){
                                let value = this.props.send_message(this.state.texto)
                                if(value){
                                    this.setState({texto:''},()=>{
                                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            
                                        });
                                }
                            }
                        }} onChange={(e)=>this.setState({texto:e.target.value})} value={this.state.texto} name="msg" placeholder="Mensagem..." className="form-control msg_input" style={{resize:'none',height:'100%'}} />
                        <div className="send_msg" onClick={()=>{
                            if(this.props.send_message(this.state.texto)){
                                this.setState({texto:''},()=>{
                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        
                                    });
                            }
                        }}
                        style={{display: 'flex',justifyContent: 'center',alignItems: 'center',marginLeft:'5px'}}>
                        <i className="mdi mdi-send" style={{fontSize: '1.4rem'}}></i>

                    </div>
                </div>}

                {this.props.processo.chat_type =='fiscal_to_student' && <div
                    style={{height:'70px',backgroundColor:'#f2f2f2',borderTop:'1px solid lightgrey',padding:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <p style={{marginBottom:0,textAlign:'center',fontSize:15}}>Você não pode enviar mensagens</p>

                </div>}

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        mensagens: state.AppReducer.mensagens,
        socket: state.AppReducer.socket,
        faculdade:state.AppReducer.faculdade,
        show_call:state.AppReducer.show_call,
    }
);

export default withRouter(connect(mapsStateToProps, { logout,mudarMensagens })(Chat));


