import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarRedacoes, logout, mudarProcesso, mudarAvisoLinhas } from '../actions/AppActions';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';
import PDFViewer from 'pdf-viewer-reactjs'
import { Document, Page } from 'react-pdf';

class Pdf extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pages_pdf: []
        }
    }

    

    render() {
        let apoio = this.props.apoio;
        return (
            <div style={{
                maxHeight: 500, background: '#f0f0f0',
                marginTop: '30px',
                paddingTop: '30px',
                paddingBottom: '30px'
            }} className="overflow-auto">

                <Document
                    loading={() => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="spinner-border text-primary" role="status">
                    </div></div>)}
                    onLoadSuccess={(e) => {
                        console.log(e._pdfInfo.numPages);
                        let pages_pdf = [];
                        if(this.state.pages_pdf.length==0){
                            for (let i = 0; i < e._pdfInfo.numPages; i++) {
                                pages_pdf.push(1);
                            }
                            this.setState({ pages_pdf });
                        }
                       
                    }}
                    file={{
                        url: apoio.arquivo
                    }}
                >

                    {this.state.pages_pdf.map((page, id2) => {

                        return (
                            <Page scale={1.5} key={id2} pageIndex={id2} className="pdf_page" />
                        );
                    }
                    )}


                </Document>
            </div>
        );
    }
}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { mudarRedacoes, logout, mudarProcesso, mudarAvisoLinhas })(Pdf);