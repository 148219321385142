import React from 'react';

const INITIAL_STATE = {
    token: null,
    user: {},
    faculdade: {},
    devices_camera: [],
    devices_mic: [],
    device_select: {},
    stream: null,
    stream_screen: null,

    loading_home: true,
    change_cam_modal: false,
    sala: { id: -1 },
    processo: null,
    socket: null,
    comunicado_geral: {},
    tempo_atual: '',
    redacoes: [],
    provas: [],
    entrevistas: [],
    questionarios: [],

    mensagens: [],
    categorias: [],
    ultrapassou_linhas: {},
    worker: null,
    fotos_usuario: [],
    object_worker: null,
    processo_resultado: null,
    screenExtended:false,
    andamento:false,
    connect:false,
    request_user:null,
    show_call:false

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'change_state':
            return {
                ...state,
                ...action.payload
            };


        case 'fotos_usuario':
            return {
                ...state,
                fotos_usuario: action.payload,
            };
        case 'worker':
            return {
                ...state,
                worker: action.payload,
            };
        case 'object_worker':
            return {
                ...state,
                object_worker: action.payload,
            };
        case 'ultrapassou_linhas':
            return {
                ...state,
                ultrapassou_linhas: action.payload,
            };
        case 'categorias':
            return {
                ...state,
                categorias: action.payload,
            };
        case 'mensagens':
            return {
                ...state,
                mensagens: action.payload,
            };
        case 'provas':
            return {
                ...state,
                provas: action.payload,
            };
        case 'redacoes':
            return {
                ...state,
                redacoes: action.payload,
            };
        case 'tempo_atual':
            return {
                ...state,
                tempo_atual: action.payload,
            };
        case 'sala':
            return {
                ...state,
                sala: action.payload,
            };
        case 'processo':
            return {
                ...state,
                processo: action.payload,
            };
        case 'comunicado_geral':
            return {
                ...state,
                comunicado_geral: action.payload,
            };
        case 'socket':
            return {
                ...state,
                socket: action.payload,
            };
        case 'change_cam_modal':
            return {
                ...state,
                change_cam_modal: action.payload,
            };
        case 'stream':
            return {
                ...state,
                stream: action.payload,
            };
        case 'devices':
            return {
                ...state,
                devices_camera: action.payload.devices_camera,
                devices_mic: action.payload.devices_mic,
                device_select: action.payload.device_select,
            };
        case 'loading_home':
            return {
                ...state,
                loading_home: action.payload,
            };
        case 'logout':
            if(state.stream_screen!=null){
                state.stream_screen.getTracks().map((track)=>track.stop())
            }
            if(state.stream!=null){
                state.stream.getTracks().map((track)=>track.stop())
            }
            return {
                ...state,
                token: '',
                user: {},
                faculdade: {},
                devices_camera: [],
                devices_mic: [],
                device_select: {},
                stream: null,
                stream_screen: null,
                loading_home: true,
                change_cam_modal: false,
                sala: { id: -1 },
                processo: null,
                socket: null,
                comunicado_geral: {},
                tempo_atual: '',
                redacoes: [],
                provas: [],
                mensagens: [],
                categorias: [],
                ultrapassou_linhas: {}
            };
        case 'faculdade':
            return {
                ...state,
                faculdade: action.payload,
            };
        case 'user':
            return {
                ...state,
                user: action.payload,
            };
        case 'login':
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,

            };
        default:
            return { ...state };
    }
};
