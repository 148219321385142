import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarRedacoes, logout, mudarProcesso, mudarAvisoLinhas } from '../actions/AppActions';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';
import Pdf from './pdf';


class Redacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            redirect: false,
            path: '/processo',
            loading_save: false,
            show_success: false,
            show_error: false,
            msg_erro: '',
            show_lines_warning: false,
            show_warning_finish: false,
            tab: 0,
            pages_pdf: {},
            line_limit:40,
            fontSize:null

        }
        this.textoRef = React.createRef();
        this.tituloRef = React.createRef();
        this.lineCount = React.createRef();

        this.dropInput = this.dropInputFunction.bind(this);
        this.copyInput = this.copyInputFunction.bind(this);

        this.pasteInput = this.pasteInputFunction.bind(this);
        this.cutInput = this.cutInputFunction.bind(this);

        this.changeInput = this.changeInputFunction.bind(this);

        this.texto_keyup_ = this.texto_keyup.bind(this);
        this.texto_keydown_ = this.texto_keydown.bind(this);



        // this.videoRef=null;
    }

    changeInputFunction(e,force=false) {
        if(this.props.show_answer==true && force==false){
            return;
        }
        let redacoes = [...this.props.redacoes];
        redacoes[this.props.indice].resultado.texto = e.target.value;
        if (redacoes[this.props.indice].resultado.texto[0] != '\t') {
            redacoes[this.props.indice].resultado.texto = '\t' + redacoes[this.props.indice].resultado.texto;
        }
        this.props.mudarRedacoes(redacoes);

    }

    dropInputFunction(event) {
        event.preventDefault();
    }

    copyInputFunction(event) {
        event.preventDefault();
    }

    pasteInputFunction(event) {
        event.preventDefault();
    }

    cutInputFunction(event) {
        event.preventDefault();
    }


    componentDidMount() {
        if (this.textoRef.current != null ) {

            if (this.textoRef.current.offsetHeight < this.textoRef.current.scrollHeight && !this.props.show_answer) {
                this.textoRef.current.selectionStart = 100000000;
                this.textoRef.current.selectionEnd = 100000000;
                this.setState({ show_lines_warning: true, msg: 'Você ultrapassou o limite de '+this.props.redacoes[this.props.indice].redacao.number_lines+' linhas!' });
                let ultrapassou_linhas = { ...this.props.ultrapassou_linhas };
                ultrapassou_linhas[this.props.redacoes[this.props.indice].redacao.id] = true;
                this.props.mudarAvisoLinhas(ultrapassou_linhas);
            }
            this.changeInputFunction({target:{value:this.props.redacoes[this.props.indice].resultado.texto}},true)
            this.textoRef.current.scrollTop = this.textoRef.current.scrollHeight;
            this.lineCount.current.scrollTop = this.textoRef.current.scrollHeight - this.textoRef.current.offsetHeight;
        }
    }



    finish_redacao(redacao_id, texto, titulo) {
        if(this.props.show_answer==true){
            return;
        }
        fetch(`${URL}api/finish_redacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                redacao_id: redacao_id,
                processo_id: this.props.processo.id,
                texto,
                titulo
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.status == false) {

                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    this.setState({ loading_save: false, msg_erro: resp.msg, show_error: true });
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {

                    let redacoes = [...this.props.redacoes];
                    redacoes[this.props.indice].resultado.finish = true;
                    this.props.mudarRedacoes(redacoes);
                    // this.props.mudarProcesso(resp.processo);
                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });

            }

        })
            .catch((err) => {

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    lines() {
        let lines = []
        for (let i = 1; i <= 1000; i = i + 1) {
            lines.push(<p key={i} className="count_line" style={{ lineHeight: '38px' }}>{i}</p>)
        }

        return lines;

    }

    title_keydown(e) {
        let keyCode = e.keyCode || e.which;

        if (keyCode == 13) {
            e.preventDefault();
        }
        if (keyCode == 9) {
            e.preventDefault();
        }
    }

    texto_keydown(e) {
        if(this.props.show_answer==true){
            return;
        }
        let indice = this.props.indice;

        let keyCode = e.keyCode || e.which;
        let isWordCharacter = e.key.length === 1;
        // let text = this.props.redacoes[indice].resultado.texto;
        if ((isWordCharacter || keyCode == 9 || keyCode == 13) && this.textoRef.current.offsetHeight < this.textoRef.current.scrollHeight) {
            e.preventDefault();
            return false;
        }

        if (keyCode == 9) {
            e.preventDefault();
            let start = this.textoRef.current.selectionStart;
            let text = this.props.redacoes[indice].resultado.texto;
            text = text.substring(0, start) + "\t" + text.substring(start);


            let redacoes = [...this.props.redacoes];
            redacoes[indice].resultado.texto = text;
            this.props.mudarRedacoes(redacoes);
            setTimeout(() => {
                this.textoRef.current.selectionStart = start + 1;
                this.textoRef.current.selectionEnd = start + 1;
            }, 100);

            return false;

        }
    }

    speechText(text){
        try{
            speechSynthesis.cancel();
        let msg = new SpeechSynthesisUtterance();
        let voices = window.speechSynthesis.getVoices().filter((item)=>item.lang=='pt-BR');
        console.log(voices)

        msg.voice = voices[9]; 
        // msg.volume = 1; // From 0 to 1
        // msg.rate = 1; // From 0.1 to 10
        // msg.pitch = 2; // From 0 to 2
        msg.text = text;
        msg.lang = 'pt-BR';
        this.speack_actual = speechSynthesis.speak(msg);
        } catch(e){
            
        }

    }

    texto_keyup(e) {
        if(this.props.show_answer==true){
            return;
        }
        let isWordCharacter = e.key.length === 1;
        let keyCode = e.keyCode || e.which;
        let indice = this.props.indice;
        if (isWordCharacter || keyCode == 13 || keyCode == 9) {
            // console.log(this.offsetHeight+'  '+this.scrollHeight);
            if (this.textoRef.current.offsetHeight < this.textoRef.current.scrollHeight) {
                var text = this.props.redacoes[indice].resultado.texto;
                // var el = this;
                // var range = document.createRange();
                // var sel = window.getSelection();
                let i = 0;
                for (i = text.length - 1; i >= 0; i--) {
                    if (text[i] != "\n" && text[i] != " " && text[i] != "\t") {
                        break;
                    }
                }
                let start = this.textoRef.current.selectionStart;

                text = text.slice(0, i + 1);
                let redacoes = [...this.props.redacoes];
                redacoes[indice].resultado.texto = text;
                this.props.mudarRedacoes(redacoes);
                // $(this).val(text);
                // var start = sel.focusOffset;
                setTimeout(() => {
                    if (this.textoRef.current.offsetHeight < this.textoRef.current.scrollHeight) {

                        // text = text.slice(0,this.selectionStart-1)+text.slice(this.selectionStart)
                        // $(this).val(text);

                        this.textoRef.current.selectionStart = start + 1;
                        this.textoRef.current.selectionEnd = start + 1;
                        this.setState({ show_lines_warning: true, msg: 'Você ultrapassou o limite de '+this.props.redacoes[this.props.indice].redacao.number_lines+' linhas!' });
                        let ultrapassou_linhas = { ...this.props.ultrapassou_linhas };
                        ultrapassou_linhas[this.props.redacoes[indice].redacao.id] = true;
                        this.props.mudarAvisoLinhas(ultrapassou_linhas);


                        // this.textoRef.current.
                        // swal("Aviso!", "O número máximo de 30 linhas foi atingido", "warning");
                    }
                    this.textoRef.current.scrollTop = this.textoRef.current.scrollHeight;
                    this.lineCount.current.scrollTop = this.textoRef.current.scrollHeight - this.textoRef.current.offsetHeight;
                }, 100);

            }

        }
        if (this.textoRef.current.offsetHeight == this.textoRef.current.scrollHeight && this.state.msg != '') {
            this.setState({ msg: '' });
            let ultrapassou_linhas = { ...this.props.ultrapassou_linhas };
            ultrapassou_linhas[this.props.redacoes[indice].redacao.id] = false;
            this.props.mudarAvisoLinhas(ultrapassou_linhas);
        }
        this.textoRef.current.scrollTop = this.textoRef.current.scrollHeight;
        this.lineCount.current.scrollTop = this.textoRef.current.scrollHeight - this.textoRef.current.offsetHeight;

    }

    render() {
        let indice = this.props.indice;
        let redacao = this.props.redacoes[indice];
        // redacao.resultado.finish=true;
        let index_carderno = 0;

        return (
            <div style={{ marginTop: '1rem' }}>

                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {(this.props.show_answer==true || redacao.resultado.finish == false) && <div>
                    {!this.props.show_answer && <>

                    <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                        {redacao.redacao.name}</h5>
                    <hr />
                    {this.props.user?.accessibility==true && this.props.user?.need_zoom && <div style={{display:'flex',justifyContent:'center',marginTop:20}}>
                                <div className='plus_minus' style={{cursor:'pointer',fontSize:35,marginRight:5}}
                                onClick={()=>{
                                    let fontSize = this.state.fontSize;
                                    if(fontSize==null){
                                        fontSize=16;
                                    }
                                    fontSize++;
                                    this.setState({fontSize});
                                }}
                                ><i className='mdi mdi-magnify-plus'></i></div>
                                <div className='plus_minus' style={{cursor:'pointer',fontSize:35}} onClick={()=>{
                                    let fontSize = this.state.fontSize;
                                    if(fontSize==null){
                                        fontSize=16;
                                    }
                                    fontSize--;
                                    this.setState({fontSize});
                                }}><i className='mdi mdi-magnify-minus'></i></div>

                            </div>}
                    <div id='speech' hidden></div>

                    <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem', fontSize:this.state.fontSize==null? '20px' :this.state.fontSize, textAlign: 'center' }}>
                        <b>Enunciado:</b>
                    </p>
                    <div
                        className='jodit-wysiwyg'
                        dangerouslySetInnerHTML={{ __html:this.state.fontSize==null? redacao.redacao.enunciado : redacao.redacao.enunciado.replaceAll('font-size','') }} style={{ fontSize: this.state.fontSize==null?'1rem':this.state.fontSize, color: 'black', marginBottom: '0.4rem' }}>
                    </div>
                    {this.props.user?.accessibility==true && this.props.user?.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=redacao.redacao.enunciado
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado  <i className='mdi mdi-play'></i> </div>}
                    <hr />
                    </>}


                    {/* <div className="row">
                        <div className="col-12">
                            <p style={{ color: 'black', fontSize: '1rem', marginBottom: '1rem', marginTop: '1rem' }}><b
                                style={{ fontWeight: 'bold' }}>Textos de apoio:</b>
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Posição</th>

                                                <th>Título</th>
                                                <th>Baixar/Visualizar</th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {redacao.textos.map((apoio, id) => (
                                                <tr key={id}>
                                                    <td>{id}</td>

                                                    <td>
                                                        {apoio.titulo}
                                                    </td>
                                                    <td>
                                                        <a href={URL + 'storage/' + apoio.arquivo}
                                                            target="_blank">
                                                            <img src={URL + 'assets/images/pdf.png'}
                                                                width="30" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>

                        {redacao.textos.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum texto de apoio adicionado na redação</p>

                            </div>
                        </div>}

                    </div> */}

                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.tab}
                        hidden={this.props.show_answer==true}
                        onSelect={(k) => this.setState({ tab: k })}
                    >
                        <Tab tabClassName={'tab'} eventKey={0} title={'Folha de redação'}>
                            <br />

                            <div>
                                {redacao.redacao.show_titulo && <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <input spellCheck={false} onDrop={(event) => {
                                            event.preventDefault();
                                        }} onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()} ref={this.tituloRef} onKeyDown={this.title_keydown.bind(this)} value={redacao.resultado.titulo} onChange={(e) => {
                                            if(this.props.show_answer==true){
                                                return;
                                            }
                                            let redacoes = [...this.props.redacoes];
                                            redacoes[indice].resultado.titulo = e.target.value;
                                            this.props.mudarRedacoes(redacoes);
                                        }} name="titulo" maxLength={60} placeholder="Título" autoComplete={'off'} className="form-control" style={{ marginLeft: '3rem', borderTop: '0px', borderRight: '0px', borderLeft: '0px', paddingLeft: 0, paddingRight: 0, width: '678px', textAlign: 'center', fontSize: '18px', fontFamily: "'Courier New', Courier, monospace", fontWeight: 'bold' }} />
                                    </div>
                                </div>}
                                <br />
                                <div className="row container_scroll d-flex justify-content-center"
                                    style={{ padding: '1.25rem', overflowX: 'scroll', flexWrap: 'inherit' }}>
                                    <div style={{ width: '710px', paddingRight: 0, paddingLeft: '2rem', zIndex: 1, overflowX: 'scroll' }}>
                                        <textarea spellCheck={false} onDrop={this.dropInput} onCopy={this.copyInput} onPaste={this.pasteInput} onCut={this.cutInput} onChange={this.changeInput} value={redacao.resultado.texto} onKeyUp={this.texto_keyup_} onKeyDown={this.texto_keydown_} ref={this.textoRef} name="texto" autoComplete="off" className="lines" style={{ width: '678px', whiteSpace: 'pre-wrap' }} maxLength={10000} rows={this.props.redacoes[this.props.indice].redacao.number_lines} role="textbox" />
                                    </div>
                                    <div style={{
                                        paddingLeft: 0, paddingRight: 0,
                                        // height: '1152px', 
                                        height:Math.ceil((1152/30)*this.props.redacoes[this.props.indice].redacao.number_lines),
                                        overflow: 'hidden', position: 'absolute',
                                        width: '710px',
                                        zIndex: 0, paddingTop: '0.5rem'
                                    }} ref={this.lineCount}
                                    >
                                        {this.lines()}

                                    </div>


                                </div>


                                {!this.props.show_answer && <div className="row">
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span id="msg" style={{ color: 'red', fontSize: '25px', textAlign: 'center' }}>{this.state.msg}</span>
                                    </div>
                                    {this.props.processo.exam_per_time==true && <div className="col-12 d-flex justify-content-end align-items-center">
                                        {this.state.loading_save == true && <div className="spinner-border text-primary" role="status">
                                        </div>}
                                        {this.state.loading_save == false && <button onClick={() => {
                                            this.setState({ show_warning_finish: true });

                                        }} type="button" disabled={this.props.ultrapassou_linhas[redacao.redacao.id]} className="btn btn-success btn-lg waves-effect waves-light finish_redacao" style={this.props.ultrapassou_linhas[redacao.redacao.id] == true ? { backgroundColor: 'grey', border: '1px solid grey' } : {}}
                                        >Salvar e finalizar redação</button>}
                                    </div>}
                                </div>}
                            </div>
                        </Tab>
                        {!this.props.show_answer && redacao.textos.map((apoio, id) => (
                            <Tab key={id} tabClassName={'tab'} eventKey={id + 1} title={'Texto de apoio ' + (id + 1)}>
                                <Pdf apoio={apoio} />

                            </Tab>
                        ))}
                    </Tabs>
                </div>}
                {!this.props.show_answer &&  redacao.resultado.finish == true && <div className="text-success" style={{ padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>
                    Redação finalizada com sucesso
                </div>}

                {!this.props.show_answer && (this.props.processo.exam_per_time==false || (this.props.processo.exam_per_time==true && redacao.resultado.finish == true)) &&
                    <div>
                        {<div>
                            <br />
                            <div className='row'>
                            {this.props.processo.exam_per_time == true && <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                                            <div>
                                                <div className="text-success" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Caderno de Redação Finalizado</div>
                                                <p style={{ color: 'black', fontSize: 18 }}>Você finalizou este caderno</p>
                                            </div>
                                        </div>}
                                        
                                <div className={this.props.processo.exam_per_time == true ? 'col-12 col-md-6 d-flex align-items-center' : 'col-12 d-flex align-items-center justify-content-center'}>
                                    <div style={this.props.processo.exam_per_time == true ?{}:{width:678}}>

                                        {(this.props.provas.length > 0
                                            || this.props.redacoes.filter((item) => item.redacao.id != redacao.redacao.id).length > 0 || this.props.questionarios.length > 0
                                        ) && this.props.processo.exam_per_time==false && <div>
                                                <p style={{ color: 'black', fontSize: 18 }}>Quer ir para outro(s) caderno(s)?</p>
                                                {this.props.questionarios.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.changeTab(index);
                                                    }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.questionario.name}</span></p>
                                                })}
                                                {this.props.provas.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span className='back_top' onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.changeTab(index);
                                                    }} style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.prova.name}</span></p>
                                                })}
                                                {this.props.redacoes.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    if (item.redacao.id != redacao.redacao.id) {

                                                        return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.changeTab(index);
                                                        }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.redacao.name}</span></p>
                                                    }
                                                })}
                                            </div>

                                        }

{this.props.processo.exam_per_time == true && <>
                                                    {[...this.props.questionarios, ...this.props.provas, ...this.props.redacoes][this.props.index + 1] != undefined ? <>
                                                        <p style={{ color: 'black', fontSize: 18 }}>{'Clique abaixo para avançar para o próximo caderno'}</p>
                                                        <button type="button" id="accept" onClick={() => {
                                                            // this.setState({ select_question: this.state.select_question - 1 });
                                                            this.props.changeTab(this.props.index + 1);

                                                        }} className="btn btn-lg btn-outline-success  waves-effect waves-light" style={{ width: 'auto' }}
                                                        >Próxima avaliação <i className='mdi mdi-arrow-right'></i></button>
                                                    </> : <>
                                                        <p style={{ color: 'black', fontSize: 18 }}>{'Todos os cadernos foram finalizados. Deseja encerrar a avaliação?'}</p>
                                                        <button type="button" id="accept" onClick={() => {
                                                            this.props.finishExam();
                                                            // this.setState({ select_question: this.state.select_question - 1 });

                                                        }} className="btn btn-lg btn-outline-danger  waves-effect waves-light" style={{ width: 'auto' }}
                                                        >Encerrar e entregar avaliação <i className='mdi mdi-pencil'></i></button>
                                                    </>}

                                                </>}
                                    </div>

                                </div>
                            </div>

                            <br />
                            <br />
                            <br />

                        </div>}
                    </div>

                }



                <SweetAlert
                    warning
                    title={"Aviso!"}
                    onConfirm={() => this.setState({ show_lines_warning: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_lines_warning}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    O número máximo de {redacao.redacao.number_lines} linhas foi atingido
                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>

                <SweetAlert
                    success
                    title={"Finalizada!"}
                    onConfirm={() => this.setState({ show_success: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    Redação finalizada com sucesso!
                </SweetAlert>

                <SweetAlert
                    warning
                    title={"Você tem certeza que deseja finalizar a redação?"}
                    onConfirm={() => {
                        this.setState({ loading_save: true, show_warning_finish: false });
                        this.finish_redacao(redacao.redacao.id, redacao.resultado.texto, redacao.resultado.titulo);
                    }}
                    onCancel={() => this.setState({ show_warning_finish: false })}
                    show={this.state.show_warning_finish}
                    confirmBtnText='Finalizar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao finalizar você não poderá realizar mais nenhuma alteração nesta redação
                </SweetAlert>
            </div>
        );
    }

    onConfirm() {

    }

    onCancel() {

    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        redacoes: state.AppReducer.redacoes,
        provas: state.AppReducer.provas,
        questionarios: state.AppReducer.questionarios,
        ultrapassou_linhas: state.AppReducer.ultrapassou_linhas
    }
);

export default connect(mapsStateToProps, { mudarRedacoes, logout, mudarProcesso, mudarAvisoLinhas })(Redacao);
