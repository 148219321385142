import React, { useEffect, useRef } from 'react';
import Routes from './routes';
import {useSelector, useDispatch} from 'react-redux'


function App()  {
    const dispatch=useDispatch();
    let token = localStorage.getItem('token');
    dispatch({type:'login',payload:{token:token,user:{}}});
    const intervalRef = useRef();
    useEffect(()=>{
        intervalRef.current = setInterval(()=>{
            document.getElementsByTagName('html')[0].childNodes.forEach((elem)=>{
                if(elem.nodeName!='BODY' && elem.nodeName!='HEAD' && elem.nodeName!='TEXT'){
                    elem.remove();
                }
            })
        },1000);
        return ()=>{
            clearInterval(intervalRef.current)
        }
    },[])

    return (
        <Routes />
    );
}

export default App;
