import { useEffect, useState } from "react";
import ChatRoom from "../ChatRoom";
import ParticipantsRoom from "../ParticipantsRoom";
import Info from "../Info";

export default function RightContentWrapper({
  participants,
  focused_participant,
  setFocusedParticipant,
  show_chat,
  setShowChat,
  show_info,
  setShowInfo,
  show_participants,
  setShowParticipants,
  playVideo,
  getAdminId,
  id_candidato,
  send_message,
  get_messages_from_student,
  students,
  changeVideo,
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <ChatRoom
        {...{
          show_chat,
          send_message,
          setShowChat,
          get_messages_from_student:get_messages_from_student,
          students:students,
          id_candidato:id_candidato,
        }}
      />
      <ParticipantsRoom
        {...{
          participants,
          changeVideo,
          show_participants,
          setShowParticipants,
          focused_participant,
          setFocusedParticipant,
          playVideo,
          getAdminId:getAdminId,

        }}
      />
      <Info
        {...{
          show_info,
  setShowInfo,
          getAdminId:getAdminId,

        }}
      />
    </div>
  );
}
