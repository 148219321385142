import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarProvas, logout, mudarProcesso } from '../actions/AppActions';
import { Redirect } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tooltip } from '@mui/material';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import moment from 'moment';

const alternativa_map={
    1:'a)',
    2:'b)',
    3:'c)',
    4:'d)',
    5:'e)',
}

function encrypt(msg, pass) {


    const key = pass;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Utf8.parse('678025308de70905');
    const enc = CryptoJS.AES.encrypt(msg, keyutf, { iv: iv });

    var transitmessage = enc.toString();
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
    console.log(msg)
    console.log(transitmessage)
    return transitmessage;
}

function decrypt(transitmessage, pass) {
    const keyutf = CryptoJS.enc.Utf8.parse(pass);
    const iv = CryptoJS.enc.Utf8.parse('678025308de70905');
    const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(transitmessage) },
        keyutf,
        {
            iv: iv
        });
    const decStr = CryptoJS.enc.Utf8.stringify(dec)
    console.log('decStr', decStr);
    return decStr;
}

class Prova extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            redirect: false,
            path: '/processo',
            prova: {},
            questoes: [],
            questoes_copy: [],
            respostas: {},
            resultado: {},
            loading_resposta: {},
            open_rascunho: {},
            rascunho: {},
            fontSize:null,
            show_success: false,
            show_warning: false,
            msg_success: '',
            show_error: false,
            msg_erro: '',
            questoes_head: [],
            select_question: 1,
            loading_position: false,
            questions_blockeds:[]
        }
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.last_question=null;
        this.url = null;
        this.questoesRef = {};
        this.key_ = '01234567890123456789012345678901';
        this.key_set = encrypt('total_seconds', this.key_);
        // this.videoRef=null;
        this.questions_raw=[]
    }

    componentWillUnmount() {
        this.unmount = true;
        clearTimeout(this.timeout_salver_resposta);
        clearTimeout(this.timeout_save_position_question);
        clearTimeout(this.timeout_time_per_question);
    }

    fontSizeChange(type){
        let fontSize=this.state.fontSize;
        if(fontSize==null){
            let questoes = this.state.questoes.map((item)=>{
                item.name = item.name.replaceAll('font-size','');
                item.alternativas = item.alternativas.map((item2)=>{
                    item2.name = item2.name.replaceAll('font-size','');
                    return item2;
                })
                item.questoes=item.questoes.map((item3)=>{
                    item3.name = item3.name.replaceAll('font-size','');
                    item3.alternativas = item3.alternativas.map((item2)=>{
                        item2.name = item2.name.replaceAll('font-size','');
                        return item2;
                    })
                    return item3;
                })
                return item;
            })
            fontSize=16;
            this.setState({questoes});
        }
        if(type=='plus'){
            fontSize++;
        }
        else{
            fontSize--;
        }
        this.setState({fontSize});
    }


    componentDidMount() {
        let prova = this.props.provas[this.props.indice];
        let respostas = {}
        let questions_blockeds = []

        if (Object.values(prova.respostas).length != 0) {
            respostas = prova.respostas;
        }
        if(prova.blockeds!=null){
            try{
                questions_blockeds = JSON.parse(prova.blockeds.questions);
                console.log(questions_blockeds)
            }catch(e){

            }
        }
        let questoes_head = [];
        for (let i = 0; i < prova.questoes.length; i++) {
            if (prova.questoes[i].type_questao == 'simples' || prova.questoes[i].type_questao == 'dissertativa') {
                questoes_head.push(prova.questoes[i]);
            }
            else {
                for (let j = 0; j < prova.questoes[i].questoes.length; j++) {
                    questoes_head.push(prova.questoes[i].questoes[j]);
                }
            }
        }
        let select_question = 0;
        this.order=prova.order;
        if (prova.order != null
            // && questoes_head.length >= prova.order.select_question
        ) {
            if (questoes_head.length + 1 >= select_question) {
                select_question = prova.order.select_question;
            }
        }
        else if (this.props.processo.exam_per_time == true) {
            console.log('nao entrou')
            select_question = 0;
        }
        let storage_question = null;
        if (this.props.processo.exam_per_time == true) {
            try {
                storage_question = localStorage.getItem(this.key_set + '_' + prova.prova.id);
                if (storage_question) {
                    storage_question = JSON.parse(decrypt(storage_question, this.key_));
                    if (storage_question.question == select_question) {
                        if (parseInt(storage_question.time) < prova.time_atual && parseInt(storage_question.time) != 0) {
                            prova.time_atual = parseInt(storage_question.time);
                        }
                    }
                }
            } catch (e) {
                storage_question = null;
            }
        }
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        // console.log('storage_questiom', storage_question)
        this.url = prova.url;
        this.props.setPosition(select_question);
        this.setState({ questions_blockeds,select_question, questoes_head, prova: prova.prova, questoes: prova.questoes, questoes_copy: prova.questoes, respostas: respostas, resultado: prova.resultado }, () => {
            if (this.props.processo.exam_per_time == true && this.state.select_question != 0) {
                this.time_initial = parseInt(this.state.prova.time) * 60;
                if (prova.time_atual != null && this.order!=null) {
                    this.order={updated_at:prova.time_atual}
                    // this.seconds_diff_initial=moment(prova.time_atual).diff(moment(this.order.updated_at), 'seconds');
                }
      
                clearTimeout(this.timeout_time_per_question);
                this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
                this.seconds_diff_initial=0;
                // console.log(this.seconds_diff_initial)
                this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');

                this.timerQuestion();
                this.timeout_time_per_question = setTimeout(() => {
                    this.timerQuestion();
                }, 1000);
            }
        });
        this.unmount = false;
    }

    timerQuestion() {
        try {
            if (this.time_initial == null) {
                clearTimeout(this.timeout_time_per_question)

                return;
            }
            clearTimeout(this.timeout_time_per_question)
            this.timeout_time_per_question = setTimeout(()=>{
                this.timerQuestion();
            },1000);
            // let totalSecs = this.time_initial - 1;
            let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

            let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
            this.last_date = last_date_new;
            // console.log(diff);
            if (Math.abs(diff) >= 2) {

                this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

            }
            // console.log(this.seconds_diff);
            var totalSecs = (parseInt(this.state.prova.time) * 60) - moment().add(this.seconds_diff, 'seconds').diff(moment(this.order.updated_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'), 'seconds');

            var currentSeconds = totalSecs % 60;
            var currentHours = Math.floor((totalSecs / 60) / 60);
            var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60
            if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
            if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
            if (currentHours <= 9) currentHours = "0" + currentHours;
            if (document.getElementsByClassName('time_question_' + this.state.prova.id + '_' + this.state.select_question).length==0
                && (this.state.loading_position == true || this.state.select_question == 0 || this.state.select_question >= this.state.questoes_head.length + 1 || this.unmount == true)
            ) {
                console.log('matou timer')
                clearTimeout(this.timeout_time_per_question)
                return;
            }
            this.time_initial = totalSecs;

            if (totalSecs % 5 == 0) {
                console.log(moment().add(this.seconds_diff, 'seconds').unix());
                let data = encrypt(JSON.stringify({ time: totalSecs.toString(), question: this.state.select_question,time_current:moment().add(this.seconds_diff, 'seconds').unix() }), this.key_);
                localStorage.setItem(this.key_set + '_' + this.state.prova.id, data);
                if (totalSecs % 30 == 0 && totalSecs!=parseInt(this.state.prova.time) * 60) {
                    if (this.url == null) {
                        clearTimeout(this.timeout_time_per_question)

                        this.props.get_processo();
                        return;
                    }
                    if (totalSecs > 0) {
                        this.save_time(this.time_initial, this.state.select_question, data)
                    }
                }
            }
            if (totalSecs <= 0) {
                clearTimeout(this.timeout_time_per_question)

                totalSecs = 0;
                if (document.getElementsByClassName('time_question_' + this.state.prova.id + '_' + this.state.select_question).length !=0) {
                    [...document.getElementsByClassName('time_question_' + this.state.prova.id + '_' + this.state.select_question)].forEach((el)=>{
                        el.innerHTML='00:00:00';
                    })
                }
                this.save_position_question(this.state.select_question + 1, this.state.prova.type == 'multipla_escolha' ? undefined : {
                    questao_id: this.question_id,
                    value: this.state.respostas[this.question_id] == undefined ? '' : this.state.respostas[this.question_id],
                    prova_id: this.state.prova.id
                })
                // this.props.get_processo();
            }
            else {
                console.log(currentHours + ":" + currentMinutes + ":" + currentSeconds)
                if (document.getElementsByClassName('time_question_' + this.state.prova.id + '_' + this.state.select_question).length != 0) {
                    [...document.getElementsByClassName('time_question_' + this.state.prova.id + '_' + this.state.select_question)].forEach((el)=>{
                        el.innerHTML='' + currentHours + ":" + currentMinutes + ":" + currentSeconds;
                        // console.log(el)
                    })
                    // document.getElementById('time_question_' + this.state.prova.id + '_' + this.state.select_question).innerHTML = '' + currentHours + ":" + currentMinutes + ":" + currentSeconds;
                }
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    async save_time(time, question, data) {
        console.log(time,question,data);
        axios.put(this.url, { data: data }, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then((resp) => {
            console.log(resp)
        }).catch((error) => {
            console.log(error)
        });

    }

    componentDidUpdate(props){
        if(props.tempo_atual!=this.props.tempo_atual){
            this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
            // this.seconds_diff_initial=moment(this.props.tempo_atual).diff(moment(), 'seconds');
        }
        if(props.goTo!=this.props.goTo
            && this.props.goTo?.prova_id==this.state.prova.id ){
                clearTimeout(this.timeout_salver_resposta)
            this.save_position_question(this.props.goTo.select_question,undefined,true)
        }
    }



    salvar_resposta(questao_id, resposta_id, indice, historico) {

        fetch(`${URL}api/salvar_resposta`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prova_id: this.state.prova.id,
                questao_id,
                resposta_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else if (resp.message == 'Server Error') {
                    if (this.unmount == false) {
                        this.timeout_salver_resposta = setTimeout(() => {
                            this.salvar_resposta(questao_id, resposta_id, indice, historico)
                        }, 5000);
                    }
                }
                else if (resp.status == false) {

                    let loading_resposta = { ...this.state.loading_resposta };
                    loading_resposta[questao_id] = false;
                    let respostas = { ...this.state.respostas };
                    respostas[questao_id] = historico;
                    this.setState({ loading_resposta, msg_erro: resp.msg, show_error: true, respostas });
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {
                    let loading_resposta = { ...this.state.loading_resposta };
                    loading_resposta[questao_id] = false;
                    let provas = [...this.props.provas];
                    provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
                    this.props.mudarProvas(provas);

                    this.setState({ loading_resposta, show_success: true, msg_success: resposta_id == -1 ? 'Questão ' + indice + ') limpa com sucesso' : 'Questão ' + indice + ') respondida com sucesso' });
                    if (resp.processo == null) {
                        // this.props.mudarProcesso(null);
                        this.props.get_processo();
                        return;
                    }
                    else if (resp.processo.updated_at != this.props.processo.updated_at) {
                        this.props.mudarProcesso(resp.processo);
                    }

                }




            } catch (err) {
                console.log(err);
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                if (this.unmount == false) {
                    this.timeout_salver_resposta = setTimeout(() => {
                        this.salvar_resposta(questao_id, resposta_id, indice, historico)
                    }, 5000);
                }

            }

        })
            .catch((err) => {
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                // console.log(err);
                if (this.unmount == false) {
                    this.timeout_salver_resposta = setTimeout(() => {
                        this.salvar_resposta(questao_id, resposta_id, indice, historico)
                    }, 5000);
                }
                // this.props.mudarLoadingHome(false);
            });
    }


    save_position_question(select_question, respostas_to_save, force = false) {
        this.time_initial = null;
        clearTimeout(this.timeout_time_per_question);
        let data = {}
        if (this.state.prova.type == 'dissertativa' && respostas_to_save != undefined) {
            data = { respostas: JSON.stringify(respostas_to_save) }
        }
        if (this.props.processo.exam_per_time == true) {
            data.crypto = { data: encrypt(JSON.stringify({ time: (parseInt(this.state.prova.time) * 60).toString(), question: select_question,time_current:moment().add(this.seconds_diff, 'seconds').unix() }), this.key_) };
        }
        if (this.state.loading_position == true && force == false) {
            return;
        }
        let questao_id = this.state.questoes_head[select_question-1]? this.state.questoes_head[select_question-1].id : null;
        this.setState({ loading_position: true });
        fetch(`${URL}api/save_position_question`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prova_id: this.state.prova.id,
                select_question: select_question,
                questao_id:questao_id,
                ...data
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else if (resp.message == 'Server Error') {
                    if (this.unmount == false) {
                        this.timeout_save_position_question = setTimeout(() => {
                            this.save_position_question(select_question, respostas_to_save, true)
                        }, 5000);
                    }
                }
                else if (resp.status == false) {

                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    // let respostas = { ...this.state.respostas };
                    // respostas[questao_id] = historico;
                    // this.setState({ loading_resposta, msg_erro: resp.msg, show_error: true, respostas });
                    this.props.get_processo();

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {
                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    // let provas = [...this.props.provas];
                    // provas[this.props.indice].respostas[questao_id] = this.state.respostas[questao_id];
                    // this.props.mudarProvas(provas);
                    // this.setState({ loading_resposta, show_success: true, msg_success: 'Questão ' + indice + ') respondida com sucesso' });
                    if (resp.processo == null) {
                        // this.props.mudarProcesso(null);
                        this.props.get_processo();
                        return;
                    }
                    else if (resp.processo.updated_at != this.props.processo.updated_at) {
                        this.props.mudarProcesso(resp.processo);
                    }
                    this.order=resp.order;
                    this.props.provas[this.props.indice].order = resp.order

                    // setTimeout(()=>{
                        this.props.setPosition(select_question);
                        this.setState({ select_question: select_question, loading_position: false }, () => {
                            if (this.props.processo.exam_per_time == true) {
                                this.time_initial = parseInt(this.state.prova.time) * 60;
                                clearTimeout(this.timeout_time_per_question);
                                this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                                let data = encrypt(JSON.stringify({ time: this.time_initial.toString(), question: this.state.select_question,time_current:moment().add(this.seconds_diff, 'seconds').unix() }), this.key_);
                                this.save_time(this.time_initial, this.state.select_question, data)
                                
                                // this.seconds_diff_initial=moment().diff(moment(resp.order.updated_at), 'seconds');
                                this.seconds_diff_initial=0;
                                console.log(this.seconds_diff_initial)
    
                                this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
                                this.timerQuestion();
                                this.timeout_time_per_question = setTimeout(() => {
                                    this.timerQuestion();
                                }, 1000);
                            }
                            this.questoesRef[questao_id]?.scrollIntoView({ behavior: "smooth" });
                        })
                    // },30000);
                    


                }




            } catch (err) {
                console.log(err);
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                if (this.unmount == false) {
                    this.timeout_save_position_question = setTimeout(() => {
                        this.save_position_question(select_question, respostas_to_save, true)
                    }, 5000);
                }

            }

        })
            .catch((err) => {
                // let loading_resposta = { ...this.state.loading_resposta };
                // loading_resposta[questao_id] = false;
                // let respostas = { ...this.state.respostas };
                // respostas[questao_id] = historico;
                // this.setState({ respostas, loading_resposta, msg_erro: 'Falha ao salvar a resposta da questão ' + indice + '). Por favor, tente novamente', show_error: true });
                // console.log(err);
                if (this.unmount == false) {
                    this.timeout_save_position_question = setTimeout(() => {
                        this.save_position_question(select_question, respostas_to_save, true)
                    }, 5000);
                }
                // this.props.mudarLoadingHome(false);
            });
    }

    speechText(text){
        try{
            speechSynthesis.cancel();
        let msg = new SpeechSynthesisUtterance();
        let voices = window.speechSynthesis.getVoices().filter((item)=>item.lang=='pt-BR');
        console.log(voices)

        msg.voice = voices[9]; 
        // msg.volume = 1; // From 0 to 1
        // msg.rate = 1; // From 0.1 to 10
        // msg.pitch = 2; // From 0 to 2
        msg.text = text;
        msg.lang = 'pt-BR';
        this.speack_actual = speechSynthesis.speak(msg);
        } catch(e){
            
        }

    }


    render() {
        let indice = this.props.indice;
        let number_questao = 0;
        // console.log(this.props.provas)
        let index_carderno = 0;

        return (
            <div className="row mt-3" id={'prova__'+this.state.prova.id}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                {this.state.prova.type == 'multipla_escolha' && <div className="col-12">
                    <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                        {this.state.prova.name}</h5>
                    <hr />
                    <h6 className="mt-0 header-title" style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                        Quais questões foram respondidas?
                    </h6>
                    <div className="row">

                        {this.state.questoes_head.map((item, id) => {
                            return (
                                <div key={id} style={{ marginTop: '10px', marginLeft: 15, marginRight: 15, width: 'auto' }}>

                                    <a onClick={() => {
                                        try {
                                            if (this.state.prova.one_question_per_page == false) {
                                                if (this.props.processo.full_screen == true) {
                                                    // document.body.scrollTo({top:this.questoesRef[item.id].offsetTop+700, behavior: 'smooth'})
                                                    this.questoesRef[item.id].scrollIntoView({ behavior: "smooth" });

                                                }
                                                else {
                                                    // window.scrollTo({top:this.questoesRef[item.id].offsetTop+1000, behavior: 'smooth'})
                                                    this.questoesRef[item.id].scrollIntoView({ behavior: "smooth" });
                                                }
                                            }
                                            else if (this.state.loading_position == false) {
                                                // this.setState({select_question:id+1})
                                                if (this.state.prova.block_return == false) {
                                                    this.save_position_question(id + 1)
                                                }

                                            }
                                        }
                                        catch (e) {

                                        }
                                    }} style={{ cursor: 'pointer' }} className="smooth-goto" >
                                        {(this.props.provas[indice].respostas[item.id] == undefined) &&
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 65 }}>
                                                <b style={{ color: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? 'green' : 'black' }}>{id + 1})</b>
                                                <div style={{ border: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? '1px solid green' : '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', justifyContent: 'center', alignItems: 'center', display: 'flex', color: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? 'green' : 'black' }}> - </div>
                                            </div>}
                                        {this.props.provas[indice].respostas[item.id] != undefined && <div style={{ display: 'flex', width: 65, justifyContent: 'space-between', alignItems: 'center' }}><b style={{ color: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? 'green' : 'black' }}>{id + 1})</b>
                                            <div style={{ color: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? 'green' : 'black', border: (this.state.select_question == id + 1 && this.state.prova.one_question_per_page) ? '1px solid green' : '1px solid grey', width: '30px', height: '30px', textAlign: 'center', marginLeft: '0.5rem', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> {item.alternativas.map((item2, id2) => {
                                                if (this.props.provas[indice].respostas[item.id] == item2.id) {
                                                    return (((id2 + 1)) == 1 ? 'a)' : ((id2 + 1)) == 2 ? 'b)' : ((id2 + 1)) == 3 ? 'c)' : ((id2 + 1)) == 4 ? 'd)' : ((id2 + 1)) == 5 ? 'e)' : '');
                                                }
                                            })} </div></div>}

                                    </a>
                                </div>
                            )
                        })}



                    </div>

                </div >}
                <div className="col-12" style={this.state.fontSize!=null?{fontSize:this.state.fontSize,position:'relative'}:{position:'relative'}}>
                    
                    {this.state.loading_position == true && <div style={{ width: '100%', minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="spinner-border text-primary" role="status">
                    </div></div>}

                    {this.state.loading_position == false &&
                        <div >
                            {this.props.user?.accessibility==true && this.props.user?.need_zoom && <div style={{display:'flex',justifyContent:'center',marginTop:20}}>
                                <div className='plus_minus' style={{cursor:'pointer',fontSize:35,marginRight:5}}
                                onClick={()=>{
                                    this.fontSizeChange('plus')
                                }}
                                ><i className='mdi mdi-magnify-plus'></i></div>
                                <div className='plus_minus' style={{cursor:'pointer',fontSize:35}} onClick={()=>{
                                    this.fontSizeChange('minus')
                                }}><i className='mdi mdi-magnify-minus'></i></div>

                            </div>}
                            {this.state.select_question == 0 && this.state.prova.one_question_per_page == true && <div>
                                <hr />
                                <br />
                                <br />
                                <br />
                                <div className='row'>


                                    {<div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                                        <div>
                                            <div className="text-success" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Caderno de Questões da avaliação {this.state.prova.name}</div>
                                            <p style={{ color: 'black', fontSize: 18 }}>Clique no botão "Iniciar avaliação" para iniciar</p>
                                        </div>
                                    </div>}

                                    <div className={'col-12 col-md-6 d-flex align-items-center'}>
                                        <div>

                                            <button type="button" id="accept" onClick={() => {
                                                // this.setState({ select_question: this.state.select_question - 1 });
                                                if (this.state.select_question == 0) {
                                                    this.save_position_question(this.state.select_question + 1, undefined);
                                                }


                                            }} className="btn btn-lg btn-outline-success  waves-effect waves-light" style={{ width: 'auto' }}
                                            >Iniciar avaliação <i className='mdi mdi-play'></i></button>
                                        </div>

                                    </div>
                                </div>

                                <br />
                                <br />
                                <br />

                            </div>}
                        </div>

                    }
                    <div id='speech' hidden></div>

                    {(this.state.prova.one_question_per_page == false || (this.state.prova.one_question_per_page == true && this.state.loading_position == false)) && this.state.questoes.map((item, id) => {

                        if (item.type_questao == 'simples' || item.type_questao == 'dissertativa') {
                            number_questao = number_questao + 1;
                        }
                        let numero_atual = number_questao;
                        if (item.id == 402) {
                            // console.log('Simples: Número atual: ', numero_atual, '  Seleção atual:', this.state.select_question)
                        }
                        if (item.type_questao!='conjunto' && numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true) {
                            // console.log('question', item.id,number_questao)
                            this.question_id = item.id;
                        }
                        let number_father = number_questao;
                        // if(number_questao == this.state.select_question ){
                        //     console.log('Simples: Número atual: ', numero_atual,'  Seleção atual:',this.state.select_question)

                        // }
                        // else if(item.type_questao=='conjunto'){
                        //     // console.log('Conjunto: Número atual: ', numero_atual,'  Seleção atual:',this.state.select_question)

                        // }



                        if (this.state.prova.type == 'multipla_escolha') {
                            return (
                                <div key={id} style={{ marginTop: (this.state.prova.one_question_per_page == true && id != 0) ? 0 : 20 }}>
                                    {/* <hr style={{ borderTop: '3px solid rgba(0,0,0,.2)' }} /> */}

                                    {item.type_questao == 'simples' && (this.state.prova.one_question_per_page == false || (numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true)) && <>
                                        <div className="card">
                                            <div className="card-body" style={{
                                                background: '#fff',
                                                borderRadius: '10px'
                                            }}>

                                                {item.type_questao == 'simples' && <h6 className="mt-0 header-title d-sm-block d-none" style={{ textAlign: 'right' }}>
                                                    {item.categoria.name}</h6>}
                                                {this.props.processo.exam_per_time == true &&
                                                    <p style={{ textAlign: 'right', fontSize: 16 }}><b>Tempo restante da questão:</b> <span className={'time_question_' + this.state.prova.id + '_' + number_questao}></span></p>
                                                }

                                                {item.type_questao == 'simples' && <div className="d-sm-none d-block">
                                                    <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5>
                                                </div>}

                                                {item.type_questao == 'simples' && <div ref={(ref) => this.questoesRef[item.id] = ref} className="row" >
                                                    <div className="col-2 col-sm-1 d-sm-block d-none">
                                                        <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                            <b>{number_questao})</b></h5>
                                                    </div>
                                                    <div className="col-12 col-sm-11">
                                                       
                                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                                        {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=item.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado da questão <i className='mdi mdi-play'></i> </div>}
                                                        {this.state.questions_blockeds.includes(item.id) && <p style={{color:'red'}}>Você não pode mais alterar a resposta desta questão pois realizou uma pausa após visualizá-la</p>}
                                                        <form>
                                                            {item.alternativas.map((alternativa, id2) => (



                                                                <div key={id2} className="row">
                                                                    <div className="col-2 pt-3 pb-3 d-sm-flex d-none" >

                                                                        <input onChange={() => {
                                                                            if(this.state.questions_blockeds.includes(item.id)){
                                                                                return
                                                                            }
                                                                            let respostas = { ...this.state.respostas };
                                                                            let historico = respostas[item.id];
                                                                            respostas[item.id] = alternativa.id;
                                                                            this.setState({ respostas }, () => {
                                                                                let loading_resposta = { ...this.state.loading_resposta };
                                                                                loading_resposta[item.id] = true;
                                                                                this.setState({ loading_resposta });
                                                                                this.salvar_resposta(item.id, alternativa.id, numero_atual, historico);
                                                                            });
                                                                        }} className="form-check-input" type="radio" name="resposta_id"
                                                                            disabled={this.state.loading_resposta[item.id] == true || this.state.questions_blockeds.includes(item.id)}
                                                                            // value="{{$alternativa->id}}"
                                                                            style={{
                                                                                position: 'relative', marginLeft: 0, marginTop: 4,
                                                                                marginRight: '10px'
                                                                            }} checked={this.state.respostas[item.id] != undefined && this.state.respostas[item.id] == alternativa.id} />
                                                                        {(id2 + 1) == 1 && <b>a) </b>}
                                                                        {(id2 + 1) == 2 && <b>b) </b>}
                                                                        {(id2 + 1) == 3 && <b>c) </b>}
                                                                        {(id2 + 1) == 4 && <b>d) </b>}
                                                                        {(id2 + 1) == 5 && <b>e) </b>}
                                                                    </div>
                                                                    <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="col-10 pt-3 pb-3 d-sm-block d-none jodit-wysiwyg" style={{}}>

                                                                    </div>

                                                                    <div className="pt-3 pb-3 d-sm-none d-flex" style={{ paddingRight: 15, paddingLeft: 15, minWidth: 65 }} >

                                                                        <input onChange={() => {
                                                                             if(this.state.questions_blockeds.includes(item.id)){
                                                                                return
                                                                            }
                                                                            let respostas = { ...this.state.respostas };
                                                                            let historico = respostas[item.id];
                                                                            respostas[item.id] = alternativa.id;
                                                                            this.setState({ respostas }, () => {
                                                                                let loading_resposta = { ...this.state.loading_resposta };
                                                                                loading_resposta[item.id] = true;
                                                                                this.setState({ loading_resposta });
                                                                                this.salvar_resposta(item.id, alternativa.id, numero_atual, historico);
                                                                            });
                                                                        }} className="form-check-input" type="radio" name="resposta_id_2"
                                                                            disabled={this.state.loading_resposta[item.id] == true || this.state.questions_blockeds.includes(item.id)}
                                                                            // value="{{$alternativa->id}}"
                                                                            style={{
                                                                                position: 'relative', marginLeft: 0, marginTop: 4,
                                                                                marginRight: '10px'
                                                                            }} checked={this.state.respostas[item.id] != undefined && this.state.respostas[item.id] == alternativa.id} />
                                                                        {(id2 + 1) == 1 && <b>a) </b>}
                                                                        {(id2 + 1) == 2 && <b>b) </b>}
                                                                        {(id2 + 1) == 3 && <b>c) </b>}
                                                                        {(id2 + 1) == 4 && <b>d) </b>}
                                                                        {(id2 + 1) == 5 && <b>e) </b>}
                                                                    </div>
                                                                    <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="pt-3 pb-3 d-sm-none d-block jodit-wysiwyg" style={{ paddingRight: 15, paddingLeft: 15, minWidth: 65 }}>

                                                                    </div>
                                                                    <div className="col-12">
                                                                    {this.props.user.accessibility==true && this.props.user.need_audio_speak && <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=alternativa.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir alternativa {alternativa_map[id2+1]} <i className='mdi mdi-play'></i> </div>}
                                                                        {/* <hr /> */}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </form>

                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end mt-2">
                                                        {this.props?.processo?.show_rascunho == true && <Tooltip title={`Abrir rascunho da questão ${number_questao})`} arrow style={{ marginRight: 3 }}>
                                                            <div>

                                                                <button onClick={() => {
                                                                    let open_rascunho = { ...this.state.open_rascunho };
                                                                    open_rascunho[item.id] = !open_rascunho[item.id];
                                                                    this.setState({ open_rascunho });
                                                                    // Limpar resposta da questão {id + 1})
                                                                }} type="button" id="{{$data['prova']->id}}_{{$questao->id}}_btn_rascunho"
                                                                    className="btn btn-primary btn-sm waves-effect waves-light responder"
                                                                    data-id="{{$data['prova']->id}}_{{$questao->id}}" style={{ borderRadius: 20 }}><i style={{ fontSize: 16 }} className='fa fa-pencil-ruler'></i></button>
                                                            </div>
                                                        </Tooltip>}
                                                        {this.state.loading_resposta[item.id] == true && <div className="spinner-border text-primary" role="status">
                                                        </div>}

                                                        {!this.state.questions_blockeds.includes(item.id) && this.state.loading_resposta[item.id] != true && <Tooltip title={`Limpar resposta da questão ${number_questao})`} arrow>
                                                            <div>
                                                                <button onClick={() => {
                                                                     if(this.state.questions_blockeds.includes(item.id)){
                                                                        return
                                                                    }
                                                                    let respostas = { ...this.state.respostas };
                                                                    if (respostas[item.id] != undefined) {

                                                                        let historico = respostas[item.id];
                                                                        delete respostas[item.id];
                                                                        this.setState({ respostas }, () => {
                                                                            let loading_resposta = { ...this.state.loading_resposta };
                                                                            loading_resposta[item.id] = true;
                                                                            this.setState({ loading_resposta });
                                                                            this.salvar_resposta(item.id, -1, numero_atual, historico);
                                                                        });
                                                                    }
                                                                    // Limpar resposta da questão {id + 1})
                                                                }} type="button" id="{{$data['prova']->id}}_{{$questao->id}}_btn_resp"
                                                                    className="btn btn-secondary btn-sm waves-effect waves-light responder"
                                                                    data-id="{{$data['prova']->id}}_{{$questao->id}}" style={{ borderRadius: 20 }}><i style={{ fontSize: 16 }} className='fa fa-eraser'></i></button>
                                                            </div>

                                                        </Tooltip>}


                                                    </div>

                                                    {this.state.open_rascunho[item.id] == true && <div>
                                                        <p style={{ marginBottom: 0, fontWeight: 700 }}>Rascunho (O conteúdo escito no rascunho não será salvo):</p>
                                                        <textarea
                                                            className="form-control"
                                                            rows={5}
                                                            placeholder={'Rascunho da questão ' + number_questao + ').'}

                                                            style={{ width: '100%',fontSize:this.state.fontSize!=null?this.state.fontSize:'' }}
                                                            value={this.state.rascunho[item.id] ?? ''}
                                                            onChange={(e) => {
                                                                let rascunho = { ...this.state.rascunho }
                                                                rascunho[item.id] = e.target.value
                                                                this.setState({ rascunho });
                                                            }}
                                                        />
                                                    </div>}


                                                </div>}
                                            </div></div>
                                    </>}


                                    {item.type_questao == 'conjunto' &&

                                        <div className={(this.state.prova.one_question_per_page == false || (number_questao + 1 <= this.state.select_question && number_questao + item.questoes.length >= this.state.select_question && this.state.prova.one_question_per_page == true)) ? "card" : ""}>
                                            <div className={(this.state.prova.one_question_per_page == false || (number_questao + 1 <= this.state.select_question && number_questao + item.questoes.length >= this.state.select_question && this.state.prova.one_question_per_page == true)) ? "card-body" : ""} style={{
                                                background: '#fff',
                                                borderRadius: '10px'
                                            }}>
                                                <div className="row" >

                                                    <div className="col-12">
                                                        {(this.state.prova.one_question_per_page == false) && <>
                                                            <h6 className="mt-0 header-title d-sm-block d-none" style={{ textAlign: 'right' }}>
                                                                {item.categoria.name}</h6>

                                                            <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                                            {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=item.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado geral <i className='mdi mdi-play'></i> </div>}
                                                        </>}

                                                        {item.questoes.map((item2, id3) => {
                                                            number_questao = (number_questao + 1);
                                                            let numero_atual = number_questao;
                                                            if (numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true) {
                                                                this.question_id = item2.id;
                                                            }
                                                            // console.log('Número atual: ', numero_atual,'  Seleção atual:',this.state.select_question)

                                                            if ((this.state.prova.one_question_per_page == false || (numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true))) {
                                                                return (
                                                                    <div ref={(ref) => this.questoesRef[item2.id] = ref} className="row" key={id3}>
                                                                        <div className="col-12">

                                                                            {((numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true)) && <>
                                                                                <h6 className="mt-0 header-title d-sm-block d-none" style={{ textAlign: 'right' }}>
                                                                                    {item.categoria.name}</h6>
                                                                                {this.props.processo.exam_per_time == true &&
                                                                                    <p style={{ textAlign: 'right', fontSize: 16 }}><b>Tempo restante da questão:</b> <span className={'time_question_' + this.state.prova.id + '_' + number_questao}></span></p>
                                                                                }
                                                                                <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                                                                {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=item.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado geral <i className='mdi mdi-play'></i> </div>}
                                                                            </>}

                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-12 col-sm-1">
                                                                            <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                                                <b>{number_questao})</b></h5>
                                                                        </div>

                                                                        <div className="col-12 col-sm-11">
                                                                        {this.props.processo.exam_per_time == true &&
                                                                                    <p style={{ textAlign: 'right', fontSize: 16 }}><b>Tempo restante da questão:</b> <span className={'time_question_' + this.state.prova.id + '_' + number_questao}></span></p>
                                                                                }
                                                                            <div className='jodit-wysiwyg' dangerouslySetInnerHTML={{ __html: item2.name }}></div>
                                                                            {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=item2.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado da questão <i className='mdi mdi-play'></i> </div>}
                                                        {this.state.questions_blockeds.includes(item2.id) && <p style={{color:'red'}}>Você não pode mais alterar a resposta desta questão pois realizou uma pausa após visualizá-la</p>}

                                                                            <form>
                                                                                {item2.alternativas.map((alternativa, id2) => (



                                                                                    <div key={id2} className="row">
                                                                                        <div className="col-2 pt-3 pb-3 d-sm-flex d-none" >

                                                                                            <input onChange={() => {
                                                                                                if(this.state.questions_blockeds.includes(item2.id)){
                                                                                                    return
                                                                                                }
                                                                                                let respostas = { ...this.state.respostas };
                                                                                                let historico = respostas[item2.id];
                                                                                                respostas[item2.id] = alternativa.id;
                                                                                                this.setState({ respostas }, () => {
                                                                                                    let loading_resposta = { ...this.state.loading_resposta };
                                                                                                    loading_resposta[item2.id] = true;
                                                                                                    this.setState({ loading_resposta });
                                                                                                    this.salvar_resposta(item2.id, alternativa.id, numero_atual, historico);
                                                                                                });
                                                                                            }} className="form-check-input" type="radio" name="resposta_id"
                                                                                                disabled={this.state.loading_resposta[item2.id] == true || this.state.questions_blockeds.includes(item2.id)}
                                                                                                // value="{{$alternativa->id}}"
                                                                                                style={{
                                                                                                    position: 'relative', marginLeft: 0, marginTop: 4,
                                                                                                    marginRight: '10px',

                                                                                                }} checked={this.state.respostas[item2.id] != undefined && this.state.respostas[item2.id] == alternativa.id} />
                                                                                            {(id2 + 1) == 1 && <b>a) </b>}
                                                                                            {(id2 + 1) == 2 && <b>b) </b>}
                                                                                            {(id2 + 1) == 3 && <b>c) </b>}
                                                                                            {(id2 + 1) == 4 && <b>d) </b>}
                                                                                            {(id2 + 1) == 5 && <b>e) </b>}
                                                                                        </div>
                                                                                        <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="col-10 pt-3 pb-3 d-sm-block d-none jodit-wysiwyg" style={{}}>

                                                                                        </div>


                                                                                        <div className="pt-3 pb-3 d-sm-none d-flex" style={{ paddingRight: 15, paddingLeft: 15, minWidth: 65 }} >

                                                                                            <input onChange={() => {
                                                                                                if(this.state.questions_blockeds.includes(item2.id)){
                                                                                                    return
                                                                                                }
                                                                                                let respostas = { ...this.state.respostas };
                                                                                                let historico = respostas[item2.id];
                                                                                                respostas[item2.id] = alternativa.id;
                                                                                                this.setState({ respostas }, () => {
                                                                                                    let loading_resposta = { ...this.state.loading_resposta };
                                                                                                    loading_resposta[item2.id] = true;
                                                                                                    this.setState({ loading_resposta });
                                                                                                    this.salvar_resposta(item2.id, alternativa.id, numero_atual, historico);
                                                                                                });
                                                                                            }} className="form-check-input" type="radio" name="resposta_id_2"
                                                                                                disabled={this.state.loading_resposta[item2.id] == true || this.state.questions_blockeds.includes(item2.id)}
                                                                                                // value="{{$alternativa->id}}"
                                                                                                style={{
                                                                                                    position: 'relative', marginLeft: 0, marginTop: 4,
                                                                                                    marginRight: '10px'
                                                                                                }} checked={this.state.respostas[item2.id] != undefined && this.state.respostas[item2.id] == alternativa.id} />
                                                                                            {(id2 + 1) == 1 && <b>a) </b>}
                                                                                            {(id2 + 1) == 2 && <b>b) </b>}
                                                                                            {(id2 + 1) == 3 && <b>c) </b>}
                                                                                            {(id2 + 1) == 4 && <b>d) </b>}
                                                                                            {(id2 + 1) == 5 && <b>e) </b>}
                                                                                        </div>
                                                                                        <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="pt-3 pb-3 d-sm-none d-block jodit-wysiwyg" style={{ paddingRight: 15, paddingLeft: 15, minWidth: 65 }}>

                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            {/* <hr/> */}
                                                                                            {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=alternativa.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir alternativa {alternativa_map[id2+1]} <i className='mdi mdi-play'></i> </div>}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </form>

                                                                        </div>



                                                                        <div className="col-12 d-flex justify-content-end mt-2">
                                                                            {this.props?.processo?.show_rascunho == true && <Tooltip title={`Abrir rascunho da questão ${number_questao})`} arrow style={{ marginRight: 3 }}>
                                                                                <div>

                                                                                    <button onClick={() => {
                                                                                        let open_rascunho = { ...this.state.open_rascunho };
                                                                                        open_rascunho[item2.id] = !open_rascunho[item2.id];
                                                                                        this.setState({ open_rascunho });
                                                                                        // Limpar resposta da questão {id + 1})
                                                                                    }} type="button" id="{{$data['prova']->id}}_{{$questao->id}}_btn_rascunho"
                                                                                        className="btn btn-primary btn-sm waves-effect waves-light responder"
                                                                                        data-id="{{$data['prova']->id}}_{{$questao->id}}" style={{ borderRadius: 20 }}><i style={{ fontSize: 16 }} className='fa fa-pencil-ruler'></i></button>
                                                                                </div>
                                                                            </Tooltip>}
                                                                            {this.state.loading_resposta[item2.id] == true && <div className="spinner-border text-primary" role="status">
                                                                            </div>}
                                                                            { !this.state.questions_blockeds.includes(item2.id) &&  this.state.prova.type == 'multipla_escolha' && this.state.loading_resposta[item2.id] != true && <Tooltip title={`Limpar resposta da questão ${number_questao})`} arrow>
                                                                                <div>

                                                                                    <button onClick={() => {
                                                                                        let respostas = { ...this.state.respostas };
                                                                                        if (respostas[item2.id] != undefined) {

                                                                                            let historico = respostas[item2.id];
                                                                                            delete respostas[item2.id];
                                                                                            this.setState({ respostas }, () => {
                                                                                                let loading_resposta = { ...this.state.loading_resposta };
                                                                                                loading_resposta[item2.id] = true;
                                                                                                this.setState({ loading_resposta });
                                                                                                this.salvar_resposta(item2.id, -1, numero_atual, historico);
                                                                                            });
                                                                                        }
                                                                                        // Limpar resposta da questão {id + 1})
                                                                                    }} type="button" id="{{$data['prova']->id}}_{{$questao->id}}_btn_resp"
                                                                                        className="btn btn-secondary btn-sm waves-effect waves-light responder"
                                                                                        data-id="{{$data['prova']->id}}_{{$questao->id}}" style={{ borderRadius: 20 }}><i style={{ fontSize: 16 }} className='fa fa-eraser'></i></button>
                                                                                </div>
                                                                            </Tooltip>}

                                                                        </div>

                                                                        {this.state.open_rascunho[item2.id] == true && <div>
                                                                            <p style={{ marginBottom: 0, fontWeight: 700 }}>Rascunho (O conteúdo escito no rascunho não será salvo):</p>
                                                                            <textarea
                                                                                className="form-control"
                                                                                placeholder={'Rascunho da questão ' + number_questao + ').'}
                                                                                rows={5}
                                                                                style={{ width: '100%',fontSize:this.state.fontSize!=null?this.state.fontSize:'' }}
                                                                                value={this.state.rascunho[item2.id] ?? ''}
                                                                                onChange={(e) => {
                                                                                    let rascunho = { ...this.state.rascunho }
                                                                                    rascunho[item2.id] = e.target.value
                                                                                    this.setState({ rascunho });
                                                                                }}
                                                                            />
                                                                        </div>}


                                                                    </div>
                                                                );
                                                            }
                                                        })}

                                                    </div>



                                                </div></div></div>}


                                    {/* {!!Form::close() !!} */}

                                </div>
                            )
                        }
                        else {
                            return (<div key={id} style={{ marginTop: (this.state.prova.one_question_per_page == true && id != 0) ? 0 : 20 }}>
                                {/* <hr style={{ borderTop: '3px solid rgba(0,0,0,.2)' }} /> */}

                                {(this.state.prova.one_question_per_page == false || (numero_atual == this.state.select_question && this.state.prova.one_question_per_page == true)) && <>
                                    <div className="card">
                                        <div className="card-body" style={{
                                            background: '#fff',
                                            borderRadius: '10px'
                                        }}>

                                            {<h6 className="mt-0 header-title d-sm-block d-none" style={{ textAlign: 'right' }}>
                                                {item.categoria.name}</h6>}
                                            {this.props.processo.exam_per_time == true &&
                                                <p style={{ textAlign: 'right', fontSize: 16 }}><b>Tempo restante da questão:</b> <span class={'time_question_' + this.state.prova.id + '_' + number_questao}></span></p>
                                            }

                                            {<div className="d-sm-none d-block">
                                                <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                    <b>{number_questao})</b></h5>
                                            </div>}

                                            {<div ref={(ref) => this.questoesRef[item.id] = ref} className="row" >
                                                <div className="col-2 col-sm-1 d-sm-block d-none">
                                                    <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5>
                                                </div>
                                                <div className="col-12 col-sm-11">

                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                                    {this.props.user.accessibility==true && this.props.user.need_audio_speak &&  <div className='listen' onClick={()=>{
                                                            document.getElementById('speech').innerHTML=item.name
                                                            this.speechText(document.getElementById('speech').textContent);
                                                        }}>Ouvir enunciado da questão <i className='mdi mdi-play'></i> </div>}
                                                    <form>
                                                        {<div>
                                                            <p style={{ marginBottom: 0, fontWeight: 700 }}>Resposta:</p>
                                                            <textarea
                                                                onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()}
                                                                className="form-control"
                                                                rows={5}
                                                                maxLength={10000}
                                                                placeholder={'Resposta da questão ' + number_questao + ').'}
                                                                style={{ width: '100%',fontSize:this.state.fontSize!=null?this.state.fontSize:'' }}
                                                                value={this.state.respostas[item.id] ?? ''}
                                                                onChange={(e) => {
                                                                    if (e.target?.value?.length <= 10000) {
                                                                        let respostas = { ...this.state.respostas }
                                                                        respostas[item.id] = e.target.value
                                                                        this.setState({ respostas });
                                                                        this.props.changeResposta(this.state.prova.id, item.id, e.target.value);
                                                                    }
                                                                    else {
                                                                        console.log('entrou ' + (e.target?.value?.length))
                                                                    }
                                                                }}
                                                            />
                                                            <p style={{ marginBottom: 0 }}>Número de caracteres: {(this.state.respostas[item.id] ?? '').length + '/10000'}</p>
                                                        </div>}
                                                    </form>

                                                </div>

                                            </div>}
                                        </div></div>
                                </>}

                            </div>);
                        }

                    })}

                    {this.state.loading_position == false &&
                        <div>
                            {(this.state.questoes.map((item, id) => {
                                if (item.type_questao == 'conjunto') {
                                    return item.questoes.length;
                                }
                                else {
                                    return 1;
                                }
                            }).reduce((a, b) => a + b, 0) < this.state.select_question || this.state.prova.one_question_per_page == false) && <div>
                                    <br />
                                    <div className='row'>
                                        {this.state.prova.one_question_per_page == true && <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                                            <div>
                                                <div className="text-success" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Caderno de Questão Finalizado</div>
                                                <p style={{ color: 'black', fontSize: 18 }}>Você chegou no fim desse caderno</p>
                                            </div>
                                        </div>}
                                        {this.state.prova.one_question_per_page == false && <div className='col-12 col-sm-1 '>
                                        </div>}

                                        <div className={this.state.prova.one_question_per_page == true ? 'col-12 col-md-6 d-flex align-items-center' : 'col-12 col-sm-11 d-flex align-items-center'}>
                                            <div>

                                                {(this.props.provas.filter((item) => item.prova.id != this.state.prova.id).length > 0
                                                    || this.props.redacoes.length > 0 || this.props.questionarios.length > 0
                                                ) && this.props.processo.exam_per_time == false && <div>
                                                        <p style={{ color: 'black', fontSize: 18 }}>Quer ir para outro(s) caderno(s)?</p>
                                                        {this.props.questionarios.map((item, id) => {
                                                            index_carderno++
                                                            let index = index_carderno - 1;
                                                            return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.changeTab(index);
                                                            }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.questionario.name}</span></p>
                                                        })}
                                                        {this.props.provas.map((item, id) => {
                                                            index_carderno++
                                                            let index = index_carderno - 1;
                                                            if (item.prova.id != this.state.prova.id) {
                                                                return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span className='back_top' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.changeTab(index);
                                                                }} style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.prova.name}</span></p>
                                                            }
                                                        })}
                                                        {this.props.redacoes.map((item, id) => {
                                                            index_carderno++
                                                            let index = index_carderno - 1;
                                                            return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.changeTab(index);
                                                            }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.redacao.name}</span></p>
                                                        })}
                                                    </div>

                                                }

                                                {this.props.processo.exam_per_time == true && <>
                                                    {[...this.props.questionarios, ...this.props.provas, ...this.props.redacoes][this.props.index + 1] != undefined ? <>
                                                        <p style={{ color: 'black', fontSize: 18 }}>{'Clique abaixo para avançar para o próximo caderno'}</p>
                                                        <button type="button" id="accept" onClick={() => {
                                                            // this.setState({ select_question: this.state.select_question - 1 });
                                                            this.props.changeTab(this.props.index + 1);

                                                        }} className="btn btn-lg btn-outline-success  waves-effect waves-light" style={{ width: 'auto' }}
                                                        >Próxima avaliação <i className='mdi mdi-arrow-right'></i></button>
                                                    </> : <>
                                                        <p style={{ color: 'black', fontSize: 18 }}>{'Todos os cadernos foram finalizados. Deseja encerrar a avaliação?'}</p>
                                                        <button type="button" id="accept" onClick={() => {
                                                            this.props.finishExam();
                                                            // this.setState({ select_question: this.state.select_question - 1 });

                                                        }} className="btn btn-lg btn-outline-danger  waves-effect waves-light" style={{ width: 'auto' }}
                                                        >Encerrar e entregar avaliação <i className='mdi mdi-pencil'></i></button>
                                                    </>}

                                                </>}
                                            </div>

                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <br />

                                </div>}
                        </div>

                    }

                    {this.state.loading_position == false && this.state.prova.one_question_per_page == true &&
                        <div>


                            <div className='row d-flex justify-content-between' style={{ marginLeft: 0, marginRight: 0 }}>
                                <div style={{ width: 'auto' }}>
                                    {this.state.prova.block_return == false && !(this.state.select_question == 1 || Object.values(this.state.loading_resposta).includes(true)) && <button type="button" id="accept" onClick={() => {
                                        // this.setState({ select_question: this.state.select_question - 1 });
                                        if (this.state.questoes.map((item, id) => {
                                            if (item.type_questao == 'conjunto') {
                                                return item.questoes.length;
                                            }
                                            else {
                                                return 1;
                                            }
                                        }).reduce((a, b) => a + b, 0) < this.state.select_question) {
                                            this.save_position_question(this.state.select_question - 1, undefined);
                                        }
                                        else {
                                            this.save_position_question(this.state.select_question - 1, {
                                                questao_id: this.question_id,
                                                value: this.state.respostas[this.question_id] == undefined ? '' : this.state.respostas[this.question_id],
                                                prova_id: this.state.prova.id
                                            });
                                        }


                                    }} className="btn btn-success  waves-effect waves-light" style={{ width: 'auto' }}
                                    >Anterior</button>}
                                </div>
                                <div style={{ width: 'auto' }}>
                                    {this.state.select_question > 0 && !(this.state.questoes.map((item, id) => {
                                        if (item.type_questao == 'conjunto') {
                                            return item.questoes.length;
                                        }
                                        else {
                                            return 1;
                                        }
                                    }).reduce((a, b) => a + b, 0) < this.state.select_question || Object.values(this.state.loading_resposta).includes(true)) && <button
                                        type="button" id="accept" onClick={() => {
                                            // if(!(this.state.questoes.map((item, id) => {
                                            //     if (item.type_questao == 'conjunto') {
                                            //         return item.questoes.length;
                                            //     }
                                            //     else {
                                            //         return 1;
                                            //     }
                                            // }).reduce((a, b) => a + b, 0) == this.state.select_question)){ //tem proxima questao
                                            //     try {
                                            //         if (this.state.prova.block_return == true
                                            //             && (this.state.respostas[this.question_id] == undefined
                                            //                 || this.state.respostas[this.question_id].trim().length == 0
                                            //             )
                                            //         ) {
                                            //             this.setState({ show_warning: true });
                                            //             return;
                                            //         }
                                            //     }
                                            //     catch (e) {

                                            //     }
                                            //     this.save_position_question(this.state.select_question + 1, {
                                            //         questao_id: this.question_id,
                                            //         value: this.state.respostas[this.question_id] == undefined ? '' : this.state.respostas[this.question_id],
                                            //         prova_id: this.state.prova.id
                                            //     });
                                            // }
                                            // else{ //não tem próxima questão

                                            // }
                                            console.log(this.question_id)
                                            console.log(this.state.respostas[this.question_id])
                                            console.log(this.state.respostas)
                                            try {
                                                if (this.state.prova.block_return == true
                                                    && (this.state.respostas[this.question_id] == undefined
                                                        || this.state.respostas[this.question_id].trim().length == 0
                                                    )
                                                ) {
                                                    this.setState({ show_warning: true });
                                                    return;
                                                }
                                            }
                                            catch (e) {

                                            }
                                            this.save_position_question(this.state.select_question + 1, {
                                                questao_id: this.question_id,
                                                value: this.state.respostas[this.question_id] == undefined ? '' : this.state.respostas[this.question_id],
                                                prova_id: this.state.prova.id
                                            });

                                            // this.setState({ select_question: this.state.select_question + 1 }, () => {
                                            //     console.log(this.state.select_question)
                                            // });

                                        }} className="btn btn-success  waves-effect waves-light" style={{ width: 'auto' }}
                                    >Próxima</button>}
                                </div>

                            </div>
                        </div>

                    }






                </div>
                <SweetAlert
                    warning
                    title={"Aviso!"}
                    onConfirm={() => this.setState({ show_warning: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_warning}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {/* Questão respondida com sucesso */}
                    Responda a pergunta para avançar
                </SweetAlert>
                <SweetAlert
                    success
                    title={"Respondida!"}
                    onConfirm={() => this.setState({ show_success: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {/* Questão respondida com sucesso */}
                    {this.state.msg_success}

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>
            </div>
        );
    }

    onConfirm() {

    }

    onCancel() {

    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        redacoes: state.AppReducer.redacoes,
        provas: state.AppReducer.provas,
        questionarios: state.AppReducer.questionarios,
        tempo_atual: state.AppReducer.tempo_atual,


    }
);

export default connect(mapsStateToProps, { mudarProvas, logout, mudarProcesso })(Prova);
