
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { changeState, logout, mudarMensagens, mudarProcesso } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';
import Chat from './Chat';

class NaoIniciada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            time_text: '88:88:88',
            chat_show: false,
            show_btn_line: false,
            loading_line: false,
            position: -1,
            position_interview:-1,
            show_fila: false,
            show_fila_interview:false,
                            loading_line_interview:false,
                            show_btn_line_interview:false,
        };
        this.last_date = moment().format('YYYY-MM-DD HH:mm:ss');
        this.unmont_ = false;
        this.iniciado=false;
    }

    incTimer() {
        // var currentMinutes = Math.floor(totalSecs / 60);
        let last_date_new = moment().format('YYYY-MM-DD HH:mm:ss');

        let diff = moment().tz('America/Sao_Paulo').diff(this.last_date, 'seconds');
        this.last_date = last_date_new;

        if (Math.abs(diff) >= 2) {
            // console.log('Tempo');
            // console.log(seconds_diff);

            // console.log('Diff');
            // console.log(diff);

            this.seconds_diff = this.seconds_diff + (-1 * diff) + 1;

            // console.log('Tempo new');
            // console.log(seconds_diff);

        }

        var totalSecs = moment(this.props.processo.inicio, 'YYYY-MM-DD HH:mm:ss').diff(moment().add(this.seconds_diff, 'seconds').format('YYYY-MM-DD HH:mm:ss'), 'seconds');

        // console.log($('#inicio_id').val())
        // console.log(moment().tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss'))
        // var totalSecs = tempo - iniciado;
        var currentHours = Math.floor((totalSecs / 60) / 60);
        var currentMinutes = Math.floor(totalSecs / 60) - currentHours * 60

        var currentSeconds = totalSecs % 60;
        if (currentSeconds <= 9) currentSeconds = "0" + currentSeconds;
        if (currentMinutes <= 9) currentMinutes = "0" + currentMinutes;
        if (currentHours <= 9) currentHours = "0" + currentHours;

        // totalSecs--;
        if (document.getElementById('timer') == null) {
            return;
        }
        document.getElementById('timer').innerHTML = currentHours + ":" + currentMinutes + ":" + currentSeconds;

        if (totalSecs <= 4200 && this.state.chat_show == false) {
            this.setState({ chat_show: true });
        }
        if (totalSecs <= 0) {
            totalSecs = 0;
            this.iniciado=true;
            this.setState({ time_text: currentHours + ":" + currentMinutes + ":" + currentSeconds });
            // location.reload();
            // this.props.get_processo();
            if (this.props.processo.simulado == false) {
                this.props.goToRules();
            }
            else {
                this.setState({ show_btn_line: true });
            }
        }
        else {

            this.setState({ time_text: currentHours + ":" + currentMinutes + ":" + currentSeconds });

            this.timer_timeout = setTimeout(function () {
                this.incTimer()
            }.bind(this), 1000);
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    

    componentDidUpdate(props){
        if(props.connect!=this.props.connect && this.props.connect==false){
            if(this.props.processo.interview==true){
                this.setState({
                    show_btn_line: false,
                    loading_line: false,
                    position: -1,
                    position_interview:-1,
                    show_fila: false,
                    show_fila_interview:false,
                    loading_line_interview:false,
                    show_btn_line_interview:true,
                })
            }
        }
    }

    componentDidMount() {
        this.seconds_diff = moment(this.props.tempo_atual).diff(moment(), 'seconds');
        this.incTimer();
        if (this.props.processo.simulado == false) {
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, this.props.processo.save_timer_pre_exam);

            if(this.props.processo.interview==true){
                this.setState({show_btn_line_interview:true});

                this.props.socket.on('line_check_interview', function (data) {
                    console.log('line check interview', data)

                    if (data.status == 'enter_now') {
                        // this.props.goToRules('?enter_now=1');
                    }
                    else if (data.status == 'line_wait') {
                        this.setState({ position_interview: data.position + 1, show_fila_interview: true });
                    }

                }.bind(this));
            }
            if(this.props.socket != null && this.props.user.accessibility && this.props.user.type_supervisor!='none' && this.props.processo.is_interview==false){
                console.log('montou',this.props.user)
                this.props.changeState({show_call:true});
            }
        }
        else {
            if (this.props.socket != null) {

                this.props.socket.on('line_check', function (data) {
                    clearTimeout(this.timerLine);
                    console.log('line check', data)
                    if (data.status == 'enter_now') {
                        this.props.goToRules('?enter_now=1');
                    }
                    else if (data.status == 'line_wait') {
                        this.setState({ position: data.position + 1, show_fila: true });
                    }
                    // setTimeout(()=>{
                    //     if(this.unmont!=true){
                    //         this.socket.connect();
                    //     }
                    // },2000);
                }.bind(this));

                
                    
                    // setTimeout(()=>{
                    //     if(this.unmont!=true){
                    //         this.socket.connect();
                    //     }
                    // },2000);
            }
        }


        this.timer_get_time = setTimeout(() => {
            this.get_time_server();
        }, 30000);
    }

  

    get_time_server() {
        if(this.iniciado==true){
            return;
        }

        fetch(`${URL}api/get_time_server`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                else {
                    if (resp.atual != null) {
                        this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    }
                }
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            } catch (err) {
                console.log(err);
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            }

        })
            .catch((err) => {
                console.log(err);
                this.timer_get_time = setTimeout(() => {
                    this.get_time_server();
                }, 30000);
            });
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        clearTimeout(this.timer_timeout);
        this.unmont_ = true;
        clearTimeout(this.timer_get_time);
        if (this.props.processo.simulado == true && this.props.socket != null) {
            this.props.socket.off('line_check');
            this.props.socket.off('line_check_interview');

        }
        clearTimeout(this.timerLine);
        clearInterval(this.lineCheckInterval)
        clearInterval(this.lineCheckInterviewInterval);


    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=not_initial&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    this.seconds_diff = moment(resp.atual).diff(moment(), 'seconds');
                    if (resp.status == false) {
                        this.props.get_processo();
                    }
                    else {
                        if (resp.processo != null && resp.processo.updated_at != this.props.processo.updated_at) {
                            this.props.mudarProcesso(resp.processo);
                        }
                        if (this.unmont_ == false) {

                            this.logs_timeout = setTimeout(() => {
                                this.salvar_log();
                            }, this.props.processo.save_timer_pre_exam);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            });
    }



    render() {

        return (
            <div >

                <hr />
                {this.state.loading_line != true && this.state.show_btn_line == false && this.state.show_fila == false && <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Tempo para começar:</p>
                </div>}
                {this.state.show_btn_line == false && this.state.show_fila == false && <div id='timer'
                    style={{ color: 'black', padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>

                </div>}


                {this.state.show_fila_interview == true && <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Você está na fila para a entrevista</p>
                    {this.state.position_interview != -1 && <div style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}><div style={{borderWidth:3,height:20,width:20}} className="spinner-border text-info" role="status"  >
                    </div> Posição: {this.state.position_interview}</div>}
                    {/* <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>O tempo de prova iniciará após o acesso. Tempo de prova após iniciar: {this.props.processo.tempo_prova + ' min'}</p> */}
                </div>}
                {this.state.show_fila_interview == false && this.state.loading_line_interview == true && <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Entrando na fila para entrevista...</p>
                </div>}

                {this.props.connect==true && this.state.show_btn_line_interview == true && this.state.loading_line_interview == false && <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <button type="button" id="accept" onClick={(e) => {
                            this.setState({ loading_line_interview: true, show_btn_line_interview: false });
                            // this.timerLine = setTimeout(() => {
                            //     this.setState({ loading_line: false, show_btn_line: true });
                            // }, 10000);

                            // this.setState({ loading_line: true });

                            this.props.socket.emit("line_check_interview", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id,sala_id:this.props.sala.id });
                            clearInterval(this.lineCheckInterviewInterval);
                            this.lineCheckInterval = setInterval(() => {
                                if (this.props.socket != null) {
                                    this.props.socket.emit("line_check_interview", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id,sala_id:this.props.sala.id });
                                }
                            }, 30000);
                            //enviar evento pro line_check e colocar interval de a cada 60 segundos verificar no line_check.

                        }} className="btn btn-info btn-lg waves-effect waves-light mt-2"
                            style={{  }}><i className='mdi mdi-call-received'></i> Entrar na fila para a entrevista</button>
                    </div>
                </div>}

                {this.state.show_fila == true && <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Você está na fila</p>
                    {this.state.position != -1 && <div style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}><div style={{borderWidth:3,height:20,width:20}} className="spinner-border text-primary" role="status"  >
                    </div> Posição: {this.state.position}</div>}
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>O tempo de prova iniciará após o acesso. Tempo de prova após iniciar: {this.props.processo.tempo_prova + ' min'}</p>

                </div>}
                {this.state.show_fila == false && this.state.loading_line == true && <div>
                    <p style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Entrando na fila...</p>
                </div>}
                {this.props.processo.simulado == false && this.state.chat_show == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <Chat 
                send_message={(m)=>this.props.send_message(m)}
                
                />}
                {this.props.connect==true && this.state.show_btn_line == true && this.state.loading_line == false && <div className="row">
                    <div className="col-12">
                        <button type="button" id="accept" onClick={(e) => {
                            this.setState({ loading_line: true, show_btn_line: false });
                            // this.timerLine = setTimeout(() => {
                            //     this.setState({ loading_line: false, show_btn_line: true });
                            // }, 10000);

                            // this.setState({ loading_line: true });

                            this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
                            clearInterval(this.lineCheckInterval);
                            this.lineCheckInterval = setInterval(() => {
                                if (this.props.socket != null) {
                                    this.props.socket.emit("line_check", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
                                }
                            }, 30000);
                            //enviar evento pro line_check e colocar interval de a cada 60 segundos verificar no line_check.

                        }} className="btn btn-success btn-lg waves-effect waves-light"
                            style={{ width: '100%' }}>Entrar na fila</button>
                    </div>
                </div>}
                {this.props.connect == false && this.props.socket!=null && <div>
                    <p style={{ fontSize: '1.2rem', color: 'red', textAlign: 'center' }}>Sua conexão parece estar instável...</p>
                </div>}
                {this.state.loading_line == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        token: state.AppReducer.token,
        socket: state.AppReducer.socket,
        sala: state.AppReducer.sala,
        connect: state.AppReducer.connect,
        user: state.AppReducer.user,
        mensagens: state.AppReducer.mensagens,

    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarProcesso,mudarMensagens,changeState })(NaoIniciada));


