import React from 'react';

import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';

import { Modal, Button } from 'react-bootstrap'

class RequestUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            loading: false,
            acao: '',
            msgErro: '',
            message:''
        }
    }
    


    

    






    render() {
      
        return (
            <>
               


                <Modal
                    show={this.props.show_pause_modal}
                    onHide={() => this.props.setModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{color:'black'}}>Pedir Pausa</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.msgErro != '' && <div>
                            <div className="alert alert-danger alert-dismissible fade show mb-0 d-flex" role="alert">
                               

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                </button> */}
                            </div>
                            <br />

                        </div>}
                        {this.props.user.id!=undefined && <div className="form-group row mb-0">
                            <div className="col-sm-12">
                                <p style={{color:'black',marginBottom:5,fontSize:16}}><b style={{marginRight: "2px"}}>Nome: </b>{this.props.user.name}</p>
                            </div>
                        </div>}
                        <p style={{color:'black',marginBottom:5,fontSize:16}}>Ao solicitar pausa, caso aprovada pelo fiscal, será concedido alguns minutos para que você se ausente do computador. </p>
                        <p style={{color:'black',marginBottom:5,fontSize:16}}>Não feche a aba de prova e não oculte a câmera durante a pausa. Caso isso ocorra será registrado como saída de prova. </p>
                        <p style={{color:'black',marginBottom:5,fontSize:16}}>Retorne antes do tempo finalizar, caso contrário, será avisado ao fiscal que a pausa finalizou e o candidato não retornou.</p>
                       
                        <div className="form-group row">
        
                            <label className="col-sm-12 col-form-label" style={{color:'black',fontSize:16}}>Justifique o motivo da pausa <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                            <div className="col-sm-12">
                                <textarea className="form-control" value={this.state.message} placeholder="Ação a ser relatada" type="text" onChange={(e) =>{
                                    if(e.target.value.length<=200){
                                        this.setState({ message: e.target.value })
                                    }
                                }} />
                                <p style={{ marginBottom: 0 }}>Número de caracteres: {this.state.message.length + '/200'}</p>

                            </div>
                        </div>
                    </Modal.Body>
                    {this.state.loading == false && <Modal.Footer>
                        <Button variant="secondary" className="btn-lg" onClick={() => this.props.setModal(false)}>Fechar</Button>
                        <Button className="btn-lg" onClick={() => {
                            if(this.state.message.length<5){
                                this.setState({msgErro:'O campo de Justificativa deve ser pelo menos 5 caracteres.'});
                            }
                            else{
                                this.props.sendRequest(this.state.message)
                                this.setState({message:'',msgErro:''});
                                this.props.setModal(false);
                            }
                        }}>Enviar</Button>

                    </Modal.Footer>}
                    {this.state.loading == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                </Modal>

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(RequestUser);
