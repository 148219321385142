
import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { mudarSocket, mudarComunicadoGeral, mudarMensagens, mudarStream, changeState } from '../actions/AppActions';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { URL_SOCKET, TURN_1, TURN_2, TURN_3, TURN_4 } from '../variables';
import moment from 'moment-timezone';

// users

class ConexaoProcesso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            mensagens_notificacao: {}
        };
        this.list_peers = {};
        this.list_peers_type_video = {}
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:turnserver.ontest.com.br:3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                // {
                //     'urls': 'turn:'+TURN_1+':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:'+TURN_2+':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:'+TURN_3+':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:'+TURN_4+':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // }

            ],
            sdpSemantics: 'unified-plan'
        };
        this.changeVideo = this.changeVideoToScreen.bind(this);
        this.good_quality_connection = {};
        this.video_track_webcam = null;
        this.video_track_screen = null;
    }

    onIceCandidate(peer, evt, admin_id) {
        if (evt.candidate) {
            this.socket.emit("candidate_to_admin", JSON.stringify({ "candidate": evt.candidate, admin_id: admin_id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id }));
        }
    }

    choose_stream(id) {
        let stream = null;
        try {
            if (this.list_peers_type_video[id] == 'screen') {
                stream = new MediaStream();
                if (this.good_quality_connection[id] == true && this.video_track_screen) {

                    stream.addTrack(this.video_track_screen);
                    stream.addTrack(this.props.stream.getAudioTracks()[0]);
                }
                else {
                    stream.addTrack(this.props.stream_screen.getVideoTracks()[0]);
                    stream.addTrack(this.props.stream.getAudioTracks()[0]);
                    let values = Object.values(this.good_quality_connection);
                    if (!values.includes(true)) {
                        if (this.video_track_screen) {
                            console.log('STOP SCREEN');

                            this.video_track_screen.stop();
                            this.video_track_screen = null;
                        }
                        if (this.video_track_webcam) {
                            console.log('STOP WEBCAM');

                            this.video_track_webcam.stop();
                            this.video_track_webcam = null;
                        }
                    }
                }
            }
            else {
                stream = this.props.stream;
                if (this.good_quality_connection[id] == true && this.video_track_webcam) {
                    stream = new MediaStream();
                    stream.addTrack(this.video_track_webcam);
                    stream.addTrack(this.props.stream.getAudioTracks()[0]);

                }
                else {
                    let values = Object.values(this.good_quality_connection);
                    if (!values.includes(true)) {
                        if (this.video_track_screen) {
                            console.log('STOP SCREEN');
                            this.video_track_screen.stop();
                            this.video_track_screen = null;
                        }
                        if (this.video_track_webcam) {
                            console.log('STOP WEBCAM');

                            this.video_track_webcam.stop();
                            this.video_track_webcam = null;
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e)
            stream = this.props.stream;
        }
        return stream;
    }

    create_peer(id, good = false) {
        this.good_quality_connection[id] = good;
        let stream = this.choose_stream(id);

        // if(this.list_peers[id]!=undefined && this.list_peers[id].connectionState=='connected'){
        //     return;
        // }
        if (this.list_peers[id] != undefined) {
            this.list_peers[id].close();
        }
        this.list_peers[id] = new RTCPeerConnection(this.configuration);

        this.list_peers[id].onicecandidate = function (evt) {
            if (!evt.candidate) return;
            // console.log("onicecandidate called");
            this.onIceCandidate(this.list_peers[id], evt, id);
        }.bind(this);
        this.list_peers[id].onaddstream = function (evt) {
            // console.log("onaddstream called");
            // document.getElementById("remoteview").srcObject = evt.stream;
        }.bind(this);
        // list_peers[id].oniceconnectionstatechange = function() {
        //     console.log(list_peers[id].iceConnectionState);
        // }
        this.list_peers[id].onconnectionstatechange = (e) => {
            console.log(e);
            console.log(this.list_peers[id].connectionState);
        }
        // let stream = new MediaStream();
        // this.props.stream.getTracks().forEach(track => stream.addTrack(track));
        // if(this.props.stream_screen!=null) this.props.stream_screen.getTracks().forEach(track => stream.addTrack(track));
        // this.list_peers[id].addStream(stream);
        this.list_peers[id].addStream(stream);


        // for (const track of this.props.stream.getTracks()) {
        //     list_peers[id].addTrack(track, this.props.stream);
        // }

        this.list_peers[id].createOffer().then(function (desc) {
            try {
                // console.log('sdp coonect');
                if (good == false) {
                    desc.sdp = this.handle_offer_sdp(desc)
                }
                console.log('peer');

                this.list_peers[id].setLocalDescription(new RTCSessionDescription(desc)).then(function (s) {
                    // console.log(s);
                }).catch(function (e) {
                    console.log('Erro webrtc');
                    console.log(e);
                    this.list_peers[id].close();
                    this.create_peer(id);
                });

                this.socket.emit("sdp", JSON.stringify({ "sdp": desc, admin_id: id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id }));
            }
            catch (err) {
                // this.create_peer(id);
                console.log(err);
            }

        }.bind(this)).catch(function (e) {
            // console.log(e);
        });
    }


    handle_offer_sdp(offer) {
        let sdp = offer.sdp.split('\r\n');//convert to an concatenable array
        let new_sdp = '';
        let position = null;
        sdp = sdp.slice(0, -1); //remove the last comma ','
        for (let i = 0; i < sdp.length; i++) {//look if exists already a b=AS:XXX line
            if (sdp[i].match(/b=AS:/)) {
                position = i; //mark the position
            }
        }
        if (position) {
            sdp.splice(position, 1);//remove if exists
        }
        for (let i = 0; i < sdp.length; i++) {
            if (sdp[i].match(/m=video/)) {//modify and add the new lines for video
                new_sdp += sdp[i] + '\r\n' + 'b=AS:' + '24' + '\r\n';
            }
            else {
                if (sdp[i].match(/m=audio/)) { //modify and add the new lines for audio
                    new_sdp += sdp[i] + '\r\n' + 'b=AS:' + 48 + '\r\n';
                }
                else {
                    new_sdp += sdp[i] + '\r\n';
                }
            }
        }
        return new_sdp; //return the new sdp
    }

    video_init() {

        this.socket = io.connect(URL_SOCKET, { "transports": ['websocket'], extraHeaders: { Authorization: `Bearer ${this.props.token}` } });
        this.props.mudarSocket(this.socket);


        //Send the ICE Candidate to the remote peer

        console.log(this.props.user);
        //Communications with the remote peer through signaling server
        this.socket.on("connect", function (client) {
            console.log('connect socket');
            this.props.changeState({connect:true})

            //Connection established with the signaling server
            // console.log("connected!");
            // setTimeout(() => {
            //     try {
            this.socket.emit("join", { name: 'Aluno', sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            //     }
            //     catch (e) {
            //     }
            // }, 3000)
            // this.socket.emit('get_list_admins', { sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
        }.bind(this));

        this.socket.on("aluno_desconectou_" + this.props.user.id, function (lista) {
            console.log(lista);
            if (this.unmont != true) {
                this.socket.emit("join", { name: 'Aluno', sala_id: this.props.sala.id, aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            }

        }.bind(this));

        this.socket.on("get_list_admins" + this.props.user.id, function (lista) {
            console.log(lista);
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                for (let i = 0; i < lista.length; i++) {
                    this.create_peer(lista[i]);
                }
            }
        }.bind(this));
        this.socket.on("join_stream_admin" + this.props.sala.id, function (data) {
            let id = data.admin_id;
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                this.create_peer(id);
            }

        }.bind(this));
        //Listening for the candidate message from a peer sent from onicecandidate handler
        this.socket.on("candidate", function (msg) {
            // console.log("candidate received");
            // console.log(msg);
            if (this.list_peers[JSON.parse(msg).admin_id] != undefined && this.list_peers[JSON.parse(msg).admin_id].connectionState != 'closed') {
                this.list_peers[JSON.parse(msg).admin_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
            }
        }.bind(this));


        //Listening for answer to offer sent to remote peer
        this.socket.on("answer" + this.props.user.id, function (answer) {
            // console.log("answer received");
            // console.log(answer);

            this.list_peers[JSON.parse(answer).admin_id].setRemoteDescription(new RTCSessionDescription(JSON.parse(answer).sdp));
        }.bind(this));

        this.socket.on("good_quality" + this.props.user.id, function (data) {
            // console.log("answer received");
            // console.log(answer);
            let id = data.admin_id;
            console.log('Good quality: ');
            console.log(data);
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                if (data.good != this.good_quality_connection[this.props.user.id]) {
                    this.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
                    if (data.good == true && this.props.processo.good_quality==true) {
                        if (!this.video_track_screen && this.props.stream_screen!=null) {
                            this.video_track_screen = this.props.stream_screen.getVideoTracks()[0].clone();
                            this.video_track_screen.applyConstraints({
                                height: 800,
                                frameRate: 24
                            })
                        }
                        if (!this.video_track_webcam && this.props.stream!=null) {
                            this.video_track_webcam = this.props.stream.getVideoTracks()[0].clone();
                            this.video_track_webcam.applyConstraints({
                                width: 640,
                                height: 480,
                                frameRate: 24
                            })
                        }

                    }

                    this.create_peer(id, data.good);

                }

            }
        }.bind(this));

        this.socket.on('disconnect', function () {
            console.log('desconectou socket')
            this.props.changeState({connect:false})
            // setTimeout(()=>{
            //     if(this.unmont!=true){
            //         this.socket.connect();
            //     }
            // },2000);
        }.bind(this));

        this.socket.on("reconect_user" + this.props.user.id, function (data) {
            let id = data.admin_id;
            console.log('Reconectando: ');
            console.log(data);
            if (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') {
                this.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
                this.create_peer(id);
            }

        }.bind(this));

        this.socket.on("alter_video" + this.props.user.id, function (data) {
            let id = data.admin_id;
            console.log('Alterando video: ');
            console.log(data);
            if (this.props.processo.type_fiscalization == 'all' && this.props.processo.screen_sharing == true && this.props.stream_screen != null && this.list_peers[id] != undefined
                && this.props.processo.replace_video_screen == true
            ) {
                this.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
                // this.create_peer(id);
                console.log(this.good_quality_connection[id])
                console.log(this.list_peers_type_video[id])

                if (this.list_peers_type_video[id] == undefined || this.list_peers_type_video[id] == 'webcam') {
                    this.list_peers_type_video[id] = 'screen';
                    this.list_peers[id].getSenders().map(async (sender) => {
                        if (sender.track.kind == 'video') {
                            if (this.good_quality_connection[id] == true && this.video_track_screen) {
                                await sender.replaceTrack(this.video_track_screen)
                            }
                            else {
                                await sender.replaceTrack(this.props.stream_screen.getTracks().find(t => t.kind == sender.track.kind))
                            }
                        }
                        return sender;
                    })
                }
                else {
                    this.list_peers_type_video[id] = 'webcam';
                    this.list_peers[id].getSenders().map(async (sender) => {
                        // if (sender.track.kind == 'video') {
                        //     await sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind))
                        // }
                        if (sender.track.kind == 'video') {
                            if (this.good_quality_connection[id] == true && this.video_track_webcam) {
                                await sender.replaceTrack(this.video_track_webcam)
                            }
                            else {
                                await sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind))
                            }
                        }
                        return sender;
                    })
                }

            }

        }.bind(this));

        // this.socket.on('welcome', function (data) {
        //     me = data.id;
        // });
        this.socket.on("send_msg" + this.props.user.id, function (data) {
            console.log(data);
            // $('#body_msg').append(template_receive.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, data.id_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            // $('#msg_notifi').append(template_msg_notifi.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'not' + data.id_msg));
            // setTimeout(() => {
            //     $('#not' + data.id_msg).remove();
            // }, 4000);
            let mensagens = [...this.props.mensagens];
            mensagens.push({
                type_message: 'message',
                receive: { id: this.props.user.id, name: this.props.user.name, }, receive_id: this.props.user.id,
                sender_name: data.nome_chat,
                sender_id: data.id_adm, sender: { id: data.id_adm, name: data.nome_chat }, sala_id: data.sala_id, uuid: data.id_msg, visto: true, message: data.msg
            });
            let mensagens_notificacao = { ...this.state.mensagens_notificacao };
            mensagens_notificacao[data.id_msg] = { msg: data.msg, name: data.nome_chat, id: data.id_msg };
            this.setState({ mensagens_notificacao });
            // $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
            setTimeout(() => {
                this.removeNotificacao(data.id_msg);
            }, 15000);
            this.props.mudarMensagens(mensagens);
            this.socket.emit('msg_receive', { id_msg: data.id_msg, sala_id: this.props.sala.id, aluno_id: this.props.user.id, admin_id: data.admin_id, faculdade_id: this.props.faculdade.id });
        }.bind(this));

        this.socket.on("msg_receive_aluno" + this.props.user.id, function (data) {
            let achou = false;
            let mensagens = [...this.props.mensagens];
            for (let i = 0; i < mensagens.length; i++) {
                if (achou == false) {
                    mensagens[i].visto = true;
                }
                if (mensagens[i].uuid == data.uuid) {
                    achou = true;
                    break;
                }
            }
            this.props.mudarMensagens(mensagens);
        }.bind(this));

        this.socket.on("msg_save_aluno" + this.props.user.id, function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check');
            console.log(data);
            let mensagens = [...this.props.mensagens];
            for (let i = 0; i < mensagens.length; i++) {
                if (mensagens[i].uuid == data.id_msg) {
                    mensagens[i].visto = false;
                    break;
                }
            }
            this.props.mudarMensagens(mensagens);
        }.bind(this));

        this.socket.on("comunicado_geral" + this.props.faculdade.id, function (data) {
            let id_msg_comunicado = this.uuidv4();
            let comunicados = { ...this.props.comunicado_geral };
            comunicados[id_msg_comunicado] = { msg: data.msg, name: data.nome_chat, id: id_msg_comunicado };

            let mensagens = [...this.props.mensagens];
            mensagens.push({
                type_message: 'comunicado',
                sender_name: data.nome_chat,
                name_comunicado: data.nome_chat,
                sala_id: null, message: data.msg,
            });

            this.props.mudarMensagens(mensagens);
            this.props.mudarComunicadoGeral(comunicados);
            // $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
            setTimeout(() => {
                this.removeComunicado(id_msg_comunicado);
            }, 15000);
        }.bind(this));

        this.socket.on("comunicado_sala" + this.props.faculdade.id, function (data) {
            let id_msg_comunicado = this.uuidv4();
            let comunicados = { ...this.props.comunicado_geral };
            comunicados[id_msg_comunicado] = { msg: data.msg, name: data.nome_chat, id: id_msg_comunicado, sala_id: data.sala_id };
            let mensagens = [...this.props.mensagens];
            mensagens.push({
                type_message: 'comunicado',
                sender_name: data.nome_chat,
                name_comunicado: data.nome_chat,
                sala_id: data.sala_id, message: data.msg,
            });

            this.props.mudarComunicadoGeral(comunicados);
            this.props.mudarMensagens(mensagens);

            // $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
            setTimeout(() => {
                this.removeComunicado(id_msg_comunicado);
            }, 15000);
        }.bind(this));
    }

    removeComunicado(id_msg_comunicado) {
        let comunicados = { ...this.props.comunicado_geral };
        delete (comunicados[id_msg_comunicado]);
        this.props.mudarComunicadoGeral(comunicados);
    }

    removeNotificacao(id_msg) {
        let mensagens_notificacao = { ...this.state.mensagens_notificacao };
        delete (mensagens_notificacao[id_msg]);
        this.setState({ mensagens_notificacao });
    }

    changeVideoToScreen(event) {
        if (this.props.processo.type_fiscalization == 'all' && this.props.processo.screen_sharing == true && this.props.stream_screen != null && this.props.processo.replace_video_screen == true) {
            this.socket.emit("send_status", { aluno_id: this.props.user.id, faculdade_id: this.props.faculdade.id });
            // this.create_peer(id);
            let lista = Object.keys(this.list_peers);

            for (let i = 0; i < lista.length; i++) {
                let id = lista[i];

                if (event.type == 'blur') {
                    this.list_peers_type_video[id] = 'screen';
                    this.list_peers[id].getSenders().map(async (sender) => {
                        if (sender.track.kind == 'video') {
                            if (this.good_quality_connection[id] == true && this.video_track_screen) {
                                await sender.replaceTrack(this.video_track_screen)
                            }
                            else {
                                await sender.replaceTrack(this.props.stream_screen.getTracks().find(t => t.kind == sender.track.kind))
                            }
                        }
                        // if (sender.track.kind == 'video') {
                        //     await sender.replaceTrack(this.props.stream_screen.getTracks().find(t => t.kind == sender.track.kind))
                        // }
                        return sender;
                    })
                }
                else {
                    this.list_peers_type_video[id] = 'webcam';
                    this.list_peers[id].getSenders().map(async (sender) => {
                        if (sender.track.kind == 'video') {
                            if (this.good_quality_connection[id] == true && this.video_track_webcam) {
                                await sender.replaceTrack(this.video_track_webcam)
                            }
                            else {
                                await sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind))
                            }
                        }
                        // if (sender.track.kind == 'video') {
                        //     await sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind))
                        // }
                        return sender;
                    })
                }
            }

        }
    }

    componentDidMount() {
        this.video_init();
        if (this.props.andamento == true && this.props.processo.simulado == false) {
            window.addEventListener('blur', this.changeVideo);
            window.addEventListener('focus', this.changeVideo);
        }
    }

    componentDidUpdate(prev) {
        // console.log(prev.stream);
        // console.log(this.props.stream);
        if (prev.stream != null && this.props.stream != null && this.props.stream.id != prev.stream.id) {
            if (this.video_track_webcam) {
                this.video_track_webcam.stop();
                this.video_track_webcam = this.props.stream.getVideoTracks()[0].clone();
                this.video_track_webcam.applyConstraints({
                    width: 640,
                    height: 480,
                    frameRate: 24
                })
            }
            // this.videoRef.current.srcObject = this.props.stream
            let lista = Object.keys(this.list_peers);
            console.log(lista);
            let video_track = null;

            for (let i = 0; i < lista.length; i++) {
                // this.create_peer(lista[i]);
                if (this.list_peers_type_video[lista[i]] == undefined || this.list_peers_type_video[lista[i]] == 'webcam') {
                    if (this.good_quality_connection[lista[i]] == true && this.video_track_webcam) {
                        this.list_peers[lista[i]].getSenders().map(async (sender) => await
                            sender.replaceTrack(sender.track.kind == 'video' ? this.video_track_webcam : this.props.stream.getTracks().find(t => t.kind == sender.track.kind)))
                    }
                    else {
                        this.list_peers[lista[i]].getSenders().map(async (sender) => await
                            sender.replaceTrack(this.props.stream.getTracks().find(t => t.kind == sender.track.kind)))
                    }
                }

            }
        }

        if (prev.andamento != this.props.andamento && this.props.processo.simulado == false) {
            if (this.props.andamento == false) {
                window.removeEventListener('blur', this.changeVideo);
                window.removeEventListener('focus', this.changeVideo);
            }
            else {
                window.removeEventListener('blur', this.changeVideo);
                window.addEventListener('blur', this.changeVideo);

                window.removeEventListener('focus', this.changeVideo);
                window.addEventListener('focus', this.changeVideo);
            }
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    componentWillUnmount() {
        if (this.props.processo.simulado == false) {
            try {
                window.removeEventListener('blur', this.changeVideo);
                window.removeEventListener('focus', this.changeVideo);
            }
            catch (e) {
            }

        }

        this.unmont = true;
        this.socket.close();
        this.props.mudarSocket(null);
        if (this.props.stream_screen != null) {
            try {
                this.props.stream_screen.getVideoTracks()[0].onended = function () { }

                this.props.stream_screen.getTracks().map((track) => track.stop())
            } catch (e) {

            }

            try {
                this.video_track_screen.stop();
            } catch (e) {

            }
        }
        if (this.props.stream != null) {
            try {
                this.props.stream.oninactive = function () { }
                this.props.stream.getTracks().map((track) => track.stop())
            } catch (e) {

            }
            try {
                this.video_track_webcam.stop();
            } catch (e) {

            }
        }
        this.props.changeState({ stream: null, stream_screen: null });
        for (let i = 0; i < Object.keys(this.list_peers).length; i++) {
            this.list_peers[Object.keys(this.list_peers)[i]].close();
        }

    }


    render() {
        let comunicado_geral = Object.values(this.props.comunicado_geral);
        return (
            <div>
                <div style={{ position: 'fixed', bottom: '50px', left: '10px', zIndex: 10000000, width: '300px', display: 'flex', flexDirection: 'column-reverse', whiteSpace: 'pre-wrap' }}
                    id="msg_notifi_comunicado">
                    {comunicado_geral.map((item, id) => (
                        <div key={item.id} className="alert alert-secondary    alert-dismissible fade show d-flex" role="alert" style={{ backgroundColor: 'white', border: '1px dashed gray' }}>
                            <div style={{ marginRight: 10 }}>

                                <button onClick={() => this.removeComunicado(item.id)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    {/* <span aria-hidden="true">×</span> */}
                                </button>
                            </div>

                            <div>

                                <strong style={{ color: 'black' }}>{item.name}</strong>
                                <p style={{ margin: 0, color: 'black' }}>{item.msg}</p>

                            </div>

                        </div>
                    ))}

                </div>
                <div style={{ position: 'fixed', bottom: '50px', right: '10px', zIndex: 10000000, width: '300px', display: 'flex', flexDirection: 'column-reverse', whiteSpace: 'pre-wrap' }}
                    id="msg_notifi">
                    {Object.values(this.state.mensagens_notificacao).map((item, id) => (

                        <div key={id} className="alert alert-success bg-success text-white border-0 alert-dismissible fade show d-flex" role="alert">
                            <div style={{ marginRight: 10 }}>
                                <button onClick={() => this.removeNotificacao(item.id)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    {/* <span aria-hidden="true">×</span> */}
                                </button>
                            </div>
                            <div>

                                <strong>{item.name}</strong>
                                <p style={{ margin: 0 }}>{item.msg}</p>
                            </div>

                        </div>
                    ))}



                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade,
        sala: state.AppReducer.sala,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        comunicado_geral: state.AppReducer.comunicado_geral,
        stream: state.AppReducer.stream,
        stream_screen: state.AppReducer.stream_screen,

        mensagens: state.AppReducer.mensagens,
        andamento: state.AppReducer.andamento,
    }
);

export default connect(mapsStateToProps, { mudarSocket, mudarComunicadoGeral, mudarMensagens, changeState })(ConexaoProcesso);


