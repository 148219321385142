import React, { Component, useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';

import { Form, FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Input from '@mui/material/Input';
import { Checkbox, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { changeState, logout } from "../actions/AppActions";
import { URL } from "../variables";
import Attention from "./ReusableComponents/Attention";

class Questionario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading_screen: false,
            enunciado: '',

            loading_save: false,
            msgErro: '',
            correta: '',
            respostas: {},
            show_warning_finish: false,
            show_error: false,
            show_success: false
        };

        this.last_change = true;


    }



    renderTypeQuestao(e) {
        if (e == 'multipla_escolha') {
            return 'Escolha múltipla';
        }
        if (e == 'paragrafo') {
            return 'Parágrafo';
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout_save_questionario);
    }





    componentDidMount() {
        if (this.props.questionarios[this.props.indice].resultado.finish == false) {
            this.timeout_save_questionario = setTimeout(() => {
                this.salvar_questionario();
            }, this.props.processo.save_timer);
        }
        // let respostas = {};
        // for (let i = 0; i < this.props.questionarios[this.props.indice].questionario.questoes.length; i++) {
        //     let questao = this.props.questionarios[this.props.indice].questionario.questoes[i];
        //     respostas[questao.id] = { type: questao.type_questao, 'alternativa_id': '', textos: {}, checkboxs: {} };
        //     for (let j = 0; j < questao.alternativas_inputs.length; j++) {
        //         let alternativa = questao.alternativas_inputs[j];
        //         if (alternativa.type == 'texto') {
        //             respostas[questao.id]['textos'][alternativa.uuid] = { 'texto': '', 'required': alternativa.required };
        //         }
        //         else if (alternativa.type == 'checkbox') {
        //             respostas[questao.id]['checkboxs'][alternativa.uuid] = false;
        //         }
        //     }
        // }
        // console.log(respostas)
        // this.setState({ respostas, visao_candidato: false });
    }

    salvar_questionario() {
        // for(let i=0;i<Object.keys(this.props.questionarios[this.props.indice].respostas).length;i++){
        //     let resposta = this.props.questionarios[this.props.indice].respostas[Object.keys(this.props.questionarios[this.props.indice].respostas)[i]];
        //     console.log(resposta);
        //     if(resposta['type']=='multipla_escolha' && resposta['alternativa_id']==null){
        //         this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //         return;
        //     }
        //     if(resposta['type']=='paragrafo'){
        //         let texto = Object.values(resposta['textos'])[0]['texto'];
        //         if((texto==null || texto.trim().length==0) && (Object.values(resposta['checkboxs']).length==0 || Object.values(resposta['checkboxs'])[0]==false)){
        //             this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //             return;
        //         }
        //     }
        //     if(resposta['type']=='multipla_escolha'){
        //         for(let j=0;j<Object.values(resposta['textos']).length;j++){
        //             let texto = resposta['textos'][Object.keys(resposta['textos'])[j]];
        //             if((texto['texto']==null || texto['texto'].trim().length==0) && ((texto['required']=='required') || (isUuid(texto['required']) && texto['required']==resposta['alternativa_id']))){
        //                 this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //                 return;
        //             }
        //         }
        //     }
        // }

        if (this.last_change == false) {
            this.last_change = true;

            console.log('Executando salvar questionário');

            fetch(`${URL}api/salvar_respostas_questionario`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',

                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    processo_id: this.props.processo.id,
                    questionario_id: this.props.questionarios[this.props.indice].questionario.id,
                    respostas: this.props.questionarios[this.props.indice].respostas
                })
            }).then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log(resp);
                    if (resp.message == "Unauthenticated.") {
                        localStorage.removeItem('token');
                        // this.setState({redirect:true,path:'/'});
                        this.props.logout();
                    }
                    // if (resp.status == false) {
                    //     this.props.get_processo();
                    //     // let loading_resposta = { ...this.state.loading_resposta };
                    //     // loading_resposta[questao_id] = false;
                    //     // this.setState({ loading_save: false, msg_erro: resp.msg, show_error: true });
                    //     //   setTimeout(()=>{
                    //     //     this.get_processo();
                    //     //   });
                    //     this.last_change=false;

                    // }
                    else {
                        // let questionarios = [...this.props.questionarios];
                        // questionarios[this.props.indice].resultado.finish = true;
                        // this.props.changeState({ questionarios });
                        // this.props.mudarProcesso(resp.processo);
                        // this.setState({ loading_save: false, show_success: true });
                        if (this.props.questionarios[this.props.indice].resultado.finish == false) {
                            this.timeout_save_questionario = setTimeout(() => {
                                this.salvar_questionario();
                            }, this.props.processo.save_timer);
                        }

                    }






                } catch (err) {
                    console.log(err);
                    this.last_change = false;

                    if (this.props.questionarios[this.props.indice].resultado.finish == false) {
                        this.timeout_save_questionario = setTimeout(() => {
                            this.salvar_questionario();
                        }, this.props.processo.save_timer);
                    }
                    // this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });

                }

            })
                .catch((err) => {
                    this.last_change = false;

                    // this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });
                    console.log(err);
                    if (this.props.questionarios[this.props.indice].resultado.finish == false) {
                        this.timeout_save_questionario = setTimeout(() => {
                            this.salvar_questionario();
                        }, this.props.processo.save_timer);
                    }
                    // this.props.mudarLoadingHome(false);
                });
        }
        else {
            console.log('Não foi executado salvar questionário');

            if (this.props.questionarios[this.props.indice].resultado.finish == false) {
                this.timeout_save_questionario = setTimeout(() => {
                    this.salvar_questionario();
                }, this.props.processo.save_timer);
            }
        }

    }




    finalizar_questionario() {
        // for(let i=0;i<Object.keys(this.props.questionarios[this.props.indice].respostas).length;i++){
        //     let resposta = this.props.questionarios[this.props.indice].respostas[Object.keys(this.props.questionarios[this.props.indice].respostas)[i]];
        //     console.log(resposta);
        //     if(resposta['type']=='multipla_escolha' && resposta['alternativa_id']==null){
        //         this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //         return;
        //     }
        //     if(resposta['type']=='paragrafo'){
        //         let texto = Object.values(resposta['textos'])[0]['texto'];
        //         if((texto==null || texto.trim().length==0) && (Object.values(resposta['checkboxs']).length==0 || Object.values(resposta['checkboxs'])[0]==false)){
        //             this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //             return;
        //         }
        //     }
        //     if(resposta['type']=='multipla_escolha'){
        //         for(let j=0;j<Object.values(resposta['textos']).length;j++){
        //             let texto = resposta['textos'][Object.keys(resposta['textos'])[j]];
        //             if((texto['texto']==null || texto['texto'].trim().length==0) && ((texto['required']=='required') || (isUuid(texto['required']) && texto['required']==resposta['alternativa_id']))){
        //                 this.setState({ loading_save: false, msg_erro: 'Você não respondeu todas as questões', show_error: true });
        //                 return;
        //             }
        //         }
        //     }
        // }



        fetch(`${URL}api/finalizar_questionario`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                processo_id: this.props.processo.id,
                questionario_id: this.props.questionarios[this.props.indice].questionario.id,
                respostas: this.props.questionarios[this.props.indice].respostas
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.status == false) {

                    // let loading_resposta = { ...this.state.loading_resposta };
                    // loading_resposta[questao_id] = false;
                    this.setState({ loading_save: false, msg_erro: resp.msg, show_error: true });
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });

                }
                else {

                    let questionarios = [...this.props.questionarios];
                    questionarios[this.props.indice].resultado.finish = true;
                    this.props.changeState({ questionarios });
                    this.last_change = true;
                    // this.props.mudarProcesso(resp.processo);
                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });

            }

        })
            .catch((err) => {

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    render() {
        let index_carderno = 0;
        return (
            Object.keys(this.props.questionarios[this.props.indice].respostas).length != 0 && <div className="row" style={{ marginTop: '1rem' }}>

                {this.props.questionarios[this.props.indice].resultado.finish == true &&
                    <div>
                        <br />

                        <div className="row">

                            <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                                <div>
                                    <div className="text-success" style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>Questionário finalizada com sucesso</div>
                                    <p style={{ color: 'black', fontSize: 18 }}>Você chegou no fim desse caderno</p>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 d-flex align-items-center'>
                                <div>

                                    {(this.props.provas.length > 0
                                        || this.props.redacoes.length > 0 || this.props.questionarios.filter((item) => item.questionario.id != this.props.questionarios[this.props.indice].questionario.id).length > 0
                                    ) && <div>
                                            {this.props.processo.exam_per_time == false && <>
                                                <p style={{ color: 'black', fontSize: 18 }}>{'Quer ir para outro(s) caderno(s)?'}</p>

                                                {this.props.questionarios.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    if (item.questionario.id != this.props.questionarios[this.props.indice].questionario.id) {

                                                        return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.changeTab(index);
                                                        }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.questionario.name}</span></p>
                                                    }
                                                })}
                                                {this.props.provas.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span className='back_top' onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.changeTab(index);
                                                    }} style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.prova.name}</span></p>
                                                })}
                                                {this.props.redacoes.map((item, id) => {
                                                    index_carderno++
                                                    let index = index_carderno - 1;
                                                    return <p key={id} style={{ fontWeight: 'bold', fontSize: 18, color: '#0d6efd' }}><span onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.changeTab(index);
                                                    }} className='back_top' style={{ cursor: 'pointer' }}><i className='mdi mdi-arrow-right-bold-outline'></i>{item.redacao.name}</span></p>
                                                })}
                                            </>}



                                        </div>

                                    }

                                    {this.props.processo.exam_per_time == true && <>
                                        {[...this.props.questionarios, ...this.props.provas, ...this.props.redacoes][this.props.index + 1] != undefined ? <>
                                            <p style={{ color: 'black', fontSize: 18 }}>{'Clique abaixo para avançar para o próximo caderno'}</p>
                                            <button type="button" id="accept" onClick={() => {
                                                // this.setState({ select_question: this.state.select_question - 1 });
                                                this.props.changeTab(this.props.index + 1);

                                            }} className="btn btn-lg btn-outline-success  waves-effect waves-light" style={{ width: 'auto' }}
                                            >Próxima avaliação <i className='mdi mdi-arrow-right'></i></button>
                                        </> : <>
                                            <p style={{ color: 'black', fontSize: 18 }}>{'Todos os cadernos foram finalizados. Deseja encerrar a avaliação?'}</p>
                                            <button type="button" id="accept" onClick={() => {
                                                // this.setState({ select_question: this.state.select_question - 1 });
                                                this.props.finishExam();

                                            }} className="btn btn-lg btn-outline-danger  waves-effect waves-light" style={{ width: 'auto' }}
                                            >Encerrar e entregar avaliação <i className='mdi mdi-pencil'></i></button>
                                        </>}

                                    </>}
                                </div>

                            </div>




                        </div>
                        <br />
                        <br />
                        <br />
                    </div>

                }
                {
                    this.props.questionarios[this.props.indice].resultado.finish == false && <div className="col-12">

                        <div style={{}}>
                            <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center', marginBottom: '1rem' }}>
                                {this.props.questionarios[this.props.indice].questionario.name}</h5>

                            {this.props.questionarios[this.props.indice].questionario.questoes != undefined && this.props.questionarios[this.props.indice].questionario.questoes.map((questao, id5) => {
                                return (Object.values(this.props.questionarios[this.props.indice].respostas).length != 0 && <div key={id5} style={{ marginTop: 15, border: "1px solid #dadce0", borderRadius: 8, transition: "box-shadow .25s cubic-bezier(0,0,0.2,1)", backgroundColor: '#fff', padding: 15 }}>

                                    <InputGroup>
                                        {/* {<InputGroup>
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label>Tipo de questão <Attention /></Form.Label>
                                        <p style={{ marginBottom: 0 }}>{this.renderTypeQuestao(questao.type_questao)}</p>

                                       
                                    </div>
                                </InputGroup>} */}

                                        <div className="flex fdcolumn flex1 ">
                                            <Form.Label >{id5 + 1}) <Attention /></Form.Label>
                                            <p style={{ marginBottom: 0 }}>{questao.name}</p>

                                        </div>
                                    </InputGroup>



                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={this.props.questionarios[this.props.indice].respostas[questao.id]['alternativa_id']}
                                        onChange={(e) => {
                                            let questionarios = [...this.props.questionarios];
                                            let respostas = { ...this.props.questionarios[this.props.indice].respostas };
                                            respostas[questao.id]['alternativa_id'] = e.target.value;
                                            questionarios[this.props.indice].respostas = respostas;
                                            this.props.changeState({ questionarios });
                                            this.last_change = false;

                                        }}
                                    >
                                        {questao.alternativas_inputs.map((item, id) => {
                                            if (item.type == 'checkbox') {
                                                return (<InputGroup key={id} style={{ marginTop: 10 }}>
                                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                                        if (questao.type_questao == 'paragrafo') {
                                                            let questionarios = [...this.props.questionarios];
                                                            let respostas = { ...this.props.questionarios[this.props.indice].respostas };
                                                            respostas[questao.id]['checkboxs'][item.uuid] = e.target.checked;
                                                            respostas[questao.id]['textos'][Object.keys(respostas[questao.id]['textos'])[0]]['texto'] = '';
                                                            questionarios[this.props.indice].respostas = respostas;

                                                            this.props.changeState({ questionarios });
                                                            this.last_change = false;
                                                        }

                                                    }} value={item.uuid} checked={this.props.questionarios[this.props.indice].respostas[questao.id]['checkboxs'][item.uuid]} />} style={{ flexGrow: 1 }} label={item.name} />

                                                </InputGroup>);
                                            }
                                            else if (item.type == 'radio') {
                                                return (
                                                    <InputGroup key={id} style={{ marginTop: 10 }}>

                                                        <FormControlLabel control={<Radio value={item.uuid} />} style={{ color: 'black' }} label={item.name} />


                                                    </InputGroup>
                                                );
                                            }
                                            else if (item.type == 'texto') {
                                                return (
                                                    (questao.type_questao == 'paragrafo'
                                                        || this.props.questionarios[this.props.indice].respostas[questao.id]['textos'][item.uuid]['required'] == 'required'
                                                        || this.props.questionarios[this.props.indice].respostas[questao.id]['textos'][item.uuid]['required'] == 'not_required'
                                                        || (this.props.questionarios[this.props.indice].respostas[questao.id]['alternativa_id'] == this.props.questionarios[this.props.indice].respostas[questao.id]['textos'][item.uuid]['required'])) && <div key={id} style={{ marginTop: 10 }}>
                                                        {questao.type_questao == 'multipla_escolha' && <p style={{ marginBottom: 0, fontWeight: 'bold' }}>{item.name}</p>}
                                                        <InputGroup >
                                                            <Input style={{ flexGrow: 1 }} disabled={questao.type_questao == 'paragrafo' && this.props.questionarios[this.props.indice].respostas[questao.id]['checkboxs'][Object.keys(this.props.questionarios[this.props.indice].respostas[questao.id]['checkboxs'])] == true} placeholder={item.name} value={this.props.questionarios[this.props.indice].respostas[questao.id]['textos'][item.uuid]['texto']} onChange={(e) => {
                                                                let questionarios = [...this.props.questionarios];
                                                                let respostas = { ...this.props.questionarios[this.props.indice].respostas };
                                                                respostas[questao.id]['textos'][item.uuid]['texto'] = e.target.value;
                                                                questionarios[this.props.indice].respostas = respostas;
                                                                this.last_change = false;

                                                                this.props.changeState({ questionarios });
                                                            }} />

                                                            {questao.type_questao == 'multipla_escolha' && this.props.questionarios[this.props.indice].respostas[questao.id]['textos'][item.uuid]['required'] != 'not_required' && <Attention />}

                                                        </InputGroup>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </RadioGroup>
                                </div>);

                            })}
                            <div className="col-12 d-flex justify-content-end align-items-center" style={{ marginTop: 20 }}>
                                {this.state.loading_save == true && <div className="spinner-border text-primary" role="status">
                                </div>}
                                {this.state.loading_save == false && <button onClick={() => {
                                    this.setState({ show_warning_finish: true });

                                }} type="button" className="btn btn-success btn-lg waves-effect waves-light finish_redacao"
                                >Salvar e finalizar questionário</button>}
                            </div>
                        </div>

                        <SweetAlert
                            danger
                            title={"Erro!"}
                            onConfirm={() => this.setState({ show_error: false })}
                            // onCancel={this.onCancel.bind(this)}
                            show={this.state.show_error}
                            confirmBtnText='Ok'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="info"
                            // cancelBtnBsStyle="success"
                            showCancel={false}
                        >
                            {this.state.msg_erro}

                        </SweetAlert>

                        <SweetAlert
                            success
                            title={"Finalizada!"}
                            onConfirm={() => this.setState({ show_success: false })}
                            // onCancel={this.onCancel.bind(this)}
                            show={this.state.show_success}
                            confirmBtnText='Ok'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="success"
                            // cancelBtnBsStyle="success"
                            showCancel={false}
                        >
                            Questionário finalizado com sucesso!
                        </SweetAlert>

                        <SweetAlert
                            warning
                            title={"Você tem certeza que deseja finalizar o questionário?"}
                            onConfirm={() => {
                                this.setState({ loading_save: true, show_warning_finish: false });

                                this.finalizar_questionario();
                            }}
                            onCancel={() => this.setState({ show_warning_finish: false })}
                            show={this.state.show_warning_finish}
                            confirmBtnText='Finalizar'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="danger"
                            showCancel={true}
                        >
                            Ao finalizar você não poderá realizar mais nenhuma alteração neste questionário
                        </SweetAlert>

                    </div>
                }
            </div >

        );
    }
}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        questionarios: state.AppReducer.questionarios,
        provas: state.AppReducer.provas,
        redacoes: state.AppReducer.redacoes,

        processo: state.AppReducer.processo,

    }
);

export default withRouter(connect(mapsStateToProps, { changeState, logout })(Questionario));